export const fileToDataUri = (file) => {
  return new Promise((response) => {
    const reader = new FileReader();
    const { name } = file;
    // const {name, type, size} = file
    reader.addEventListener("loadend", () => {
      response({
        data: reader.result,
        filename: name,
      });
    });
    if (file) reader.readAsDataURL(file);
  });
};

export const deconstructFileUrl = (url) => {
  if (!url) return;

  const imagePath = new URL(url).pathname;
  const imagePathname = decodeURIComponent(
    imagePath.substring(imagePath.lastIndexOf("/") + 1)
  );
  const imageName = imagePathname.substring(imagePathname.indexOf("/") + 1);
  return {
    filename: imageName,
    data: url,
  };
};

export const isUrl = (string) => {
  if (!string) return;

  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
};
