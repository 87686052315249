import { Outlet } from "react-router-dom";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { CssBaseline, Container } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { loginRequest } from "config/msalAuthentication";
import { components, palette, typography } from "styles/Theme";
import { Navbar, Sidebar } from "layouts/components";

let theme = createTheme({
  components,
  palette,
  typography,
});
theme = responsiveFontSizes(theme);

const MainLayout = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthenticatedTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={loginRequest}
      >
        <Navbar />
        <Sidebar>
          <Container maxWidth="lg">
            <Outlet />
          </Container>
        </Sidebar>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Outlet />
      </UnauthenticatedTemplate>
    </ThemeProvider>
  );
};

export default MainLayout;
