import { styled } from "@mui/styles";
import { useTable, useSortBy } from "react-table";

const ReactTable = styled("table")(({ theme }) => ({
  borderSpacing: 0,
  border: `1px solid ${theme.palette.common.black}`,
  width: "100%",
  "& tr": {
    "&:last-child": {
      "& td": {
        borderBottom: 0,
      },
    },
  },
  "& th,td": {
    margin: 0,
    padding: "0.5rem",
    borderBottom: `1px solid ${theme.palette.common.black}`,
    borderRight: `1px solid ${theme.palette.common.black}`,
    "&:last-child": {
      borderRight: 0,
    },
  },
}));

const Table = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useSortBy
    );
  // console.log("rows:", rows);
  // console.log("headers:", headers);

  return (
    <ReactTable {...getTableProps()}>
      <thead>
        <tr>
          {headers.map((column) => {
            return (
              <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render("Header")}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                </span>
              </th>
            );
          })}
        </tr>
      </thead>

      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </ReactTable>
  );
};

export default Table;
