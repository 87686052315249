import PropsTypes from "prop-types";

import { Paper, Grid, FormHelperText } from "@mui/material";
import { styled } from "@mui/material/styles";

import { Controller } from "react-hook-form";
import { fileToDataUri } from "helpers/Utilies";

const FileInput = styled("input")({
  display: "none",
});

const CardFileInput = ({
  control,
  defaultValue,
  multiple,
  header,
  ...props
}) => {
  const onSelectImagesHandler = (onChange) => async (e) => {
    const { files } = e.target;
    if (multiple) {
      const imagesPromises = Object.values(files).map((file) =>
        fileToDataUri(file)
      );
      const images = await Promise.all(imagesPromises);
      onChange(images);
    } else {
      const image = await fileToDataUri(files[0]);
      onChange(image);
    }
  };

  return (
    <Controller
      control={control}
      name={props.name}
      defaultValue={defaultValue}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => {
        const headerWording = header ? (
          <Grid item xs={12} mb={1}>
            {header}
          </Grid>
        ) : (
          <Grid
            item
            xs={12}
            mb={{ xs: 0, md: 1 }}
            sx={(theme) => ({
              height: 0,
              [theme.breakpoints.up("md")]: {
                height: theme.spacing(3),
              },
            })}
          ></Grid>
        );

        const errorText = error?.message ? (
          <FormHelperText error={!!error} sx={{ pl: 1 }}>
            {error.message}
          </FormHelperText>
        ) : null;
        return (
          <Grid item xs={12} sm={6} md={4} mb={2}>
            <label htmlFor={`${name}__file-input`}>
              <FileInput
                id={`${name}__file-input`}
                name={name}
                multiple={multiple}
                type="file"
                accept="image/*"
                onChange={onSelectImagesHandler(onChange)}
                disabled={props.disabled}
              />
              <Paper
                elevation={0}
                sx={(theme) => ({
                  p: theme.spacing(1),
                  mx: 1,
                  bgcolor: theme.palette.grey[100],
                  cursor: "pointer",
                  ...(!!error && {
                    border: `1px solid ${theme.palette.error.main}`,
                  }),
                  "&:hover": {
                    boxShadow: theme.shadows[4],
                  },
                  "&:active": {
                    transform: "translate(1px, 1px)",
                    boxShadow: theme.shadows[0],
                  },
                })}
              >
                <Grid container item>
                  {headerWording}
                  {props.children}
                </Grid>
              </Paper>
            </label>
            {errorText}
          </Grid>
        );
      }}
    />
  );
};

CardFileInput.propTypes = {
  header: PropsTypes.node,
  multiple: PropsTypes.bool,
};

CardFileInput.defaultProps = {
  multiple: false,
  onSelectImages: () => {},
};

export default CardFileInput;
