import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

/* === Authenticated layout & Page content === */
import LoginPage from "pages/LoginPage";
import LogoutPage from "pages/LogoutPage";
/* === ======================================= */

/* === Dealer layout === */
import MainLayout from "layouts/MainLayout.jsx";
import HomePage from "pages/HomePage";
/* Machines managing */
import MachineListPage from "pages/MachineManaging/MachineListPage";
import MachineFormPage from "pages/MachineManaging/MachineFormPage";
/* Renting managing */
import BookingListPagePage from "pages/RentingManaging/BookingListPage";
import BookingDetailPage from "pages/RentingManaging/BookingDetailPage";
/* Master data managing */
import MasterDataPage from "pages/MasterDataManaging/MasterDataPage";
/* Errors */
import NotFound404Page from "pages/Error/NotFound404Page";
import AccessForbidden403Page from "pages/Error/AccessForbidden403Page";
import ServerError500Page from "pages/Error/ServerError500Page";
/* ===================== */

import { checkRole } from "helpers/Role";
import ErrorLayout from "layouts/ErrorLayout";

const RequireAuthentication = ({ children }) => {
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();
  if (isAuthenticated) return children;
  return <Navigate to="/login" state={{ from: location.pathname }} replace />;
};

const RequireRole = ({ children }) => {
  const { accounts } = useMsal();
  const { upn } = accounts[0].idTokenClaims;
  const location = useLocation();
  if (checkRole({ role: "skc", upn }) || checkRole({ role: "kin", upn }))
    return children;
  return <Navigate to="/" state={{ from: location }} replace />;
};

const Pages = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route
          index
          element={
            <RequireAuthentication>
              <HomePage />
            </RequireAuthentication>
          }
        />
        <Route
          path="news"
          element={
            <RequireAuthentication>
              <HomePage />
            </RequireAuthentication>
          }
        />
        <Route
          path="machines"
          element={
            <RequireAuthentication>
              <MachineListPage />
            </RequireAuthentication>
          }
        />
        <Route
          path="machines/add"
          element={
            <RequireAuthentication>
              <MachineFormPage />
            </RequireAuthentication>
          }
        />
        <Route
          path="machines/update/:VIN"
          element={
            <RequireAuthentication>
              <MachineFormPage />
            </RequireAuthentication>
          }
        />
        <Route
          path="bookings"
          element={
            <RequireAuthentication>
              <BookingListPagePage />
            </RequireAuthentication>
          }
        />
        <Route
          path="bookings/:bookingNo"
          element={
            <RequireAuthentication>
              <BookingDetailPage />
            </RequireAuthentication>
          }
        />
        <Route
          path="master-data"
          element={
            <RequireAuthentication>
              <RequireRole>
                <MasterDataPage />
              </RequireRole>
            </RequireAuthentication>
          }
        />
        <Route path="login" element={<LoginPage />} />
        <Route path="logout" element={<LogoutPage />} />
      </Route>
      <Route path="error" element={<ErrorLayout />}>
        <Route path="access-forbidden" element={<AccessForbidden403Page />} />
        <Route path="internal-server-error" element={<ServerError500Page />} />
      </Route>
      <Route
        path="*"
        element={
          <ErrorLayout>
            <NotFound404Page />
          </ErrorLayout>
        }
      />
    </Routes>
  );
};

export default Pages;
