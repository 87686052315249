import { Fragment, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import {
  Person as PersonIcon,
  Logout as LogoutIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import { logoutRequest } from "config/msalAuthentication";
import KINRentLogo from "assets/KIN-Rent_192.png";

const Navbar = () => {
  const { instance, accounts } = useMsal();
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);

  const upn =
    accounts.length > 0 && accounts[0]?.idTokenClaims?.upn
      ? accounts[0].idTokenClaims.upn
      : null;

  const goHomePage = () => {
    navigate("/");
  };

  const handleClickProfile = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseProfileMenu = () => {
    setAnchorEl(null);
  };

  const logoutFromMsalHandler = () => {
    instance.logoutRedirect({ ...logoutRequest, account: accounts[0] });
    // if (logoutResponse) navigate("/logout", { replace: true });
  };

  const LogoImg = styled("div")({
    flexGrow: 1,
    "& img": {
      cursor: "pointer",
    },
  });

  const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

  return (
    <Fragment>
      <AppBar
        sx={{
          backgroundColor: "common.white",
          borderBottom: (theme) => `3px solid ${theme.palette.primary.main}`,
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        position="fixed"
      >
        <Toolbar component="nav" sx={{ display: "flex" }}>
          <LogoImg>
            <img alt="logo-img" src={KINRentLogo} onClick={goHomePage} />
          </LogoImg>
          <Typography variant="subtitle1" color="common.black" mr={1}>
            {upn}
          </Typography>
          {/* <IconButton sx={{ padding: 0, mr: 1 }}>
            <SearchIcon sx={{ fontSize: 32 }} />
          </IconButton> */}
          <IconButton onClick={handleClickProfile} sx={{ padding: 0 }}>
            <PersonIcon sx={{ fontSize: 32 }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Offset />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseProfileMenu}
        onClick={handleCloseProfileMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={logoutFromMsalHandler}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

export default Navbar;
