export const STATUS = {
  DRAFT: {
    order: 0,
    nameTH: "ฉบับร่าง",
    nameEN: "draft",
    styles: { backgroundColor: "#595959", color: "#fff" },
  },
  WAITING: {
    order: 1,
    nameTH: "รอการตรวจสอบ",
    nameEN: "waiting",
    styles: { backgroundColor: "warning.light", color: "#fff" },
  },
  REJECTED: {
    order: 2,
    nameTH: "ไม่ผ่านการตรวจสอบ",
    nameEN: "rejected",
    styles: { backgroundColor: "error.main", color: "#fff" },
  },
  AVAILABLE: {
    order: 3,
    nameTH: "พร้อมให้เช่า",
    nameEN: "available",
    styles: { backgroundColor: "info.main", color: "#fff" },
  },
  BOOKED: {
    order: 4,
    nameTH: "ถูกจองแล้ว",
    nameEN: "booked",
    styles: { backgroundColor: "success.dark", color: "#fff" },
  },
  ACTIVE: {
    order: 5,
    nameTH: "อยู่ระหว่างการเช่า",
    nameEN: "active",
    styles: { backgroundColor: "success.light", color: "#fff" },
  },
  COMPLETED: {
    order: 6,
    nameTH: "เช่าเสร็จสิ้น",
    nameEN: "completed",
    styles: { backgroundColor: "success.main", color: "#fff" },
  },
  RETURN: {
    order: 7,
    nameTH: "รอการปรับสภาพ",
    nameEN: "return",
    styles: { backgroundColor: "error.light", color: "#fff" },
  },
  DISABLE: {
    order: 8,
    nameTH: "ไม่พร้อมให้เช่า",
    nameEN: "disable",
    styles: { backgroundColor: "#616161", color: "#fff" },
  },
};
