import { Outlet } from "react-router-dom";
import { CssBaseline, Box } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { components, palette, typography } from "styles/Theme";

let theme = createTheme({
  components,
  palette,
  typography,
});

const ErrorLayout = (props) => {
  const children = props.children || <Outlet />;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        {children}
      </Box>
    </ThemeProvider>
  );
};

export default ErrorLayout;
