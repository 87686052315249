import { Fragment, useState, useEffect, useRef, useContext } from "react";
import { useMsal } from "@azure/msal-react";

import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { parse, format, addDays, isAfter } from "date-fns";

import {
  Box,
  Grid,
  Typography,
  Button,
  Fab,
  Divider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import {
  ExpandMore as ExpandMoreIcon,
  FileDownload as FileDownloadIcon,
  LocalShipping as LocalShippingIcon,
  Reply as ReplyIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import AuthContext from "contexts/auth/auth-context";
import { useHttp } from "hooks";
import { InlineInput, InlineDatePicker, FileInput } from "components/HookForms";
import { Modal, Alert } from "components";
import { MAX_PRE_RESERVED_DAYS, DEFAULT_DATE_FORMAT } from "constants/DateTime";
import { isUrl } from "helpers/Utilies";
import { disableCalendarDate } from "helpers/DateTime";
import { checkRole } from "helpers/Role";

import { AD_STATUS, CLIENT_STATUS } from "constants/Booking";

yup.addMethod(yup.string, "citizenIdFormat", function (errorMessage) {
  return this.test("test-citizen-id-format", errorMessage, function (value) {
    const { path, createError } = this;
    let sum = 0;
    for (let i = 0; i < 12; i++) sum += +value.charAt(i) * (13 - i);
    return (
      (11 - (sum % 11)) % 10 === +value.charAt(12) ||
      createError({ path, message: errorMessage })
    );
  });
});

const schema = yup.object().shape({
  firstName: yup.string().required("กรุณากรอก ชื่อ"),
  lastName: yup.string().required("กรุณากรอก นามสกุล"),
  cid: yup
    .string()
    .matches(
      /* eslint-disable-next-line no-useless-escape */
      /^-?(0|INF|(0[1-7][0-7]*)|(0x[0-9a-fA-F]+)|((0|[1-9][0-9]*|(?=[\.,]))([\.,][0-9]+)?([eE]-?\d+)?))$/,
      "กรุณากรอกเฉพาะตัวเลข"
    )
    .length(13, "จำนวนหลักทั้งหมด 13 หลักเท่านั้น")
    .citizenIdFormat("เลขบัตรประจำตัวประชาชนไม่ถูกต้อง")
    .required("กรุณากรอก เลขบัตรประจำตัวประชาชน"),
  address: yup.string().required("กรุณากรอก ที่อยู่"),
  tel: yup
    .string()
    .matches(
      /* eslint-disable-next-line no-useless-escape */
      /((\+66|0)(\d{1,2}\-?\d{3}\-?\d{3,4}))|((\+๖๖|๐)([๐-๙]{1,2}\-?[๐-๙]{3}\-?[๐-๙]{3,4}))/gm,
      "เบอร์โทรศัพท์ไม่ถูกต้อง"
    )
    .required("กรุณากรอก เบอร์โทรศัพท์"),
  model: yup.string(),
  implement_1: yup.string(),
  implement_2: yup.string(),
  implement_3: yup.string(),
  VIN: yup.string(),
  engineNo: yup.string(),
  licenseNo: yup.string(),
  startDate: yup.date().typeError("กรุณาเลือก วันที่เริ่มต้น"),
  endDate: yup.date(),
  rentDays: yup.string(),
  address_del: yup.string(),
  contractADWithKIN: yup
    .object()
    .typeError("กรุณาอัพโหลด สัญญาเช่าระหว่างผู้แทนจำหน่ายและบริษัทเกษตรอินโน"),
  contractKINWithClient: yup
    .object()
    .typeError("กรุณาอัพโหลด สัญญาเช่าระหว่างบริษัทเกษตรอินโนและลูกค้าผู้เช่า"),
});

const Form = styled("form")({
  width: "100%",
});

const IFrameDoc = styled("iframe")({
  height: "90vh",
  width: "100%",
});

const BookingDetailPage = (props) => {
  const [bookingStatus, setBookingStatus] = useState({
    ad: "",
    client: "",
  });
  const [implementMachines, setImplementMachines] = useState([]);
  const [price, setPrice] = useState({
    vin: 0,
    vinDiscount: 0,
    shipping: 0,
    shippinDiscount: 0,
    total: 0,
    totalAmount: 0,
  });
  const [expandedProcedures, setExpandedProcedures] = useState(true);
  const [contractADWithKIN, setContractADWithKIN] = useState([]);
  const [contractKINWithClient, setContractKINWithClient] = useState([]);
  const [previewContract, setPreviewContract] = useState({
    contractADWithKIN: false,
    contractKINWithClient: false,
  });
  const [sendHttpSuccess, setSendHttpSuccess] = useState({
    message: "",
    status: false,
  });
  const { idToken, accessToken } = useContext(AuthContext);
  const contractADWithKINRef = useRef(null);
  const contractKINWithClientRef = useRef(null);

  const { accounts } = useMsal();
  const { upn } = accounts[0].idTokenClaims;

  const { bookingNo } = useParams();
  const navigate = useNavigate();

  const { control, getValues, setValue, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });
  const [startDate, endDate, rentDays, adContractValue, clientContractValue] =
    getValues([
      "startDate",
      "endDate",
      "rentDays",
      "contractADWithKIN",
      "contractKINWithClient",
    ]);

  const { sendRequest: fetchBookingData } = useHttp();
  const { sendRequest: fetchContract } = useHttp();
  const { isLoading: isSending, sendRequest: sendUpdateRequest } = useHttp();

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const setBookingData = (booking) => {
      setBookingStatus({ ad: booking.adStatus, client: booking.stage });
      setImplementMachines(() => {
        booking.implements.forEach((imp, idx) => {
          setValue(`implement_${idx + 1}`, imp);
        });
        return booking.implements;
      });
      setPrice({
        vin: booking.vinPrice,
        vinDiscount: booking.vinDiscount,
        shipping: booking.shippingPrice,
        shippingDiscount: booking.shippingDiscount,
        total: parseFloat(booking.price.replace(/,/g, "")),
        totalAmount: booking.totalAmount ? booking.totalAmount : 0,
      });
      console.log("total: ", parseFloat(booking.price.replace(/,/g, "")));
      console.log("total amount: ", booking.totalAmount);
      setValue("firstName", booking.name);
      setValue("lastName", booking.surname);
      setValue("cid", booking.cid);
      setValue("address", booking.address);
      setValue("tel", booking.tel);
      setValue("model", booking.model);
      setValue("VIN", booking.VIN);
      setValue("engineNo", booking.engineNo);
      setValue("licenseNo", booking.licenseNo);
      const startDateObj = parse(booking.startdate, "dd/MM/yyyy", new Date());
      setValue("startDate", startDateObj);
      const endDateObj = parse(booking.enddate, "dd/MM/yyyy", new Date());
      setValue("endDate", endDateObj);
      setValue("rentDays", `${booking.rentDays} วัน`);
      setValue("address_del", booking.address_del);
      /* round at tens */
      // setValue(
      //   "price",
      //   `${(Math.round(booking.price / 10) * 10).toLocaleString()} บาท`
      // );
      setContractADWithKIN(() => {
        if (!booking.adContract) return [];
        const adContractPath = new URL(booking.adContract).pathname;
        const adContractPathname = decodeURIComponent(
          adContractPath.substring(adContractPath.lastIndexOf("/") + 1)
        );
        const contractFilename = adContractPathname.substring(
          adContractPathname.indexOf("/") + 1
        );
        setValue("contractADWithKIN.filename", contractFilename);
        setValue("contractADWithKIN.data", booking.adContract);
        return [
          {
            filename: contractFilename,
            data: booking.adContract,
            flag_delete: false,
          },
        ];
      });
      setContractKINWithClient(() => {
        if (!booking.customerContract) return [];
        const customerContractPath = new URL(booking.customerContract).pathname;
        const customerContractPathname = decodeURIComponent(
          customerContractPath.substring(
            customerContractPath.lastIndexOf("/") + 1
          )
        );
        const contractFilename = customerContractPathname.substring(
          customerContractPathname.indexOf("/") + 1
        );
        setValue("contractKINWithClient.filename", contractFilename);
        setValue("contractKINWithClient.data", booking.customerContract);
        return [
          {
            filename: contractFilename,
            data: booking.customerContract,
            flag_delete: false,
          },
        ];
      });
    };

    const fetchHttp = (signal) => {
      fetchBookingData(
        {
          endpoint: `/booking/id/${bookingNo}`,
          headers: { id_token: idToken, access_token: accessToken },
          signal,
        },
        setBookingData
      );
    };

    fetchHttp(signal);

    return () => abortCont.abort();
  }, [fetchBookingData, idToken, accessToken, setValue, bookingNo]);

  const onChangeStartDate = (date) => {
    const [rentDaysNumber] = rentDays.split(" ");
    const newEndDate = addDays(date, +rentDaysNumber);
    setValue("endDate", newEndDate);
  };

  const toggleExpandProcedures = () => {
    setExpandedProcedures((prevExpanded) => !prevExpanded);
  };

  const onClickDownloadContract = (contract) => () => {
    fetchContract(
      {
        endpoint: `/booking/${contract}/${bookingNo}`,
        headers: { id_token: idToken, access_token: accessToken },
      },
      ({ url }) => {
        window.open(url);
      }
    );
  };

  const onSelectContract = (contract, state) => {
    if (!contract || !state) return;
    setValue(state, contract);
    const newContract = {
      ...contract,
      flag_delete: false,
    };
    const actions = {
      contractADWithKIN() {
        setContractADWithKIN((prevContract) => {
          if (prevContract.length === 1) return [...prevContract, newContract];
          return [newContract];
        });
      },
      contractKINWithClient() {
        setContractKINWithClient((prevContract) => {
          if (prevContract.length === 1) return [...prevContract, newContract];
          return [newContract];
        });
      },
    };
    return actions[state]();
  };

  const onRemoveContract = (state) => {
    if (!state) return;
    setValue(state, "");

    const callbackSnapshotSetState = (prevState) => {
      const existedContract = {
        ...prevState[0],
        flag_delete: true,
      };
      if (prevState.length === 1)
        return isUrl(prevState[0].data) ? [existedContract] : [];
      if (prevState.length === 2) {
        const onlyExistedContract = prevState.slice(0, 1);
        return onlyExistedContract;
      }
      return prevState;
    };

    const actions = {
      contractADWithKIN() {
        contractADWithKINRef.current.value = null;
        setContractADWithKIN(callbackSnapshotSetState);
      },
      contractKINWithClient() {
        contractKINWithClientRef.current.value = null;
        setContractKINWithClient(callbackSnapshotSetState);
      },
    };
    return actions[state]();
  };

  const openModalPreviewContract = (name) => {
    if (!name) return;
    const lastADContract = contractADWithKIN.at(-1);
    const lastClientContract = contractKINWithClient.at(-1);
    const actions = {
      contractADWithKIN() {
        if (isUrl(lastADContract?.data)) window.open(lastADContract.data);
        else
          setPreviewContract((prevPreviewContract) => ({
            ...prevPreviewContract,
            [name]: true,
          }));
      },
      contractKINWithClient() {
        if (isUrl(lastClientContract?.data))
          window.open(lastClientContract.data);
        else
          setPreviewContract((prevPreviewContract) => ({
            ...prevPreviewContract,
            [name]: true,
          }));
      },
    };
    return actions[name]();
  };

  const closeModalPreviewContract = (name) => () => {
    setPreviewContract((prevPreviewContract) => ({
      ...prevPreviewContract,
      [name]: false,
    }));
  };

  const submitBookingData = (formData) => {
    const adContract = contractADWithKIN.map(
      ({ filename, data, flag_delete }) => ({ filename, data, flag_delete })
    );
    const customerContract = contractKINWithClient.map(
      ({ filename, data, flag_delete }) => ({ filename, data, flag_delete })
    );
    const body = {
      ...((checkRole({ role: "kin", upn }) ||
        checkRole({ role: "skc", upn })) && {
        startdate: format(startDate, DEFAULT_DATE_FORMAT),
        enddate: format(endDate, DEFAULT_DATE_FORMAT),
      }),
      adContract,
      customerContract,
    };

    sendUpdateRequest(
      {
        endpoint: `/booking/update/${bookingNo}`,
        headers: { id_token: idToken, access_token: accessToken },
        method: "POST",
        body,
      },
      (postResponse) => {
        onToggleAlertBar("บันทึกเอกสารสัญญาเรียบร้อยแล้ว", true);
        setTimeout(() => {
          navigate("/bookings");
        }, 2000);
      }
    );
  };

  const onErrorSubmitBookingData = (error) => {
    // console.log("error: ", error);
    // console.log("adContract: ", contractADWithKIN);
    // console.log("customerContract: ", contractKINWithClient);
  };

  const submitUpdateMachineStatus = (status) => () => {
    sendUpdateRequest(
      {
        endpoint: `/booking/${status}/${bookingNo}`,
        headers: { id_token: idToken, access_token: accessToken },
        method: "PUT",
      },
      (updateResponse) => {
        console.log(updateResponse);
        onToggleAlertBar(
          `เปลี่ยน สถานะสัญญาเป็น ${
            AD_STATUS[status.toUpperCase()].nameTH
          }แล้ว`,
          true
        );
        setTimeout(() => {
          navigate("/bookings");
        }, 2000);
      }
    );
  };

  const onToggleAlertBar = (message, status) => {
    setSendHttpSuccess(() => ({
      message,
      status,
    }));
  };

  const userInforms = [
    {
      id: 1,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "firstName",
      label: "ชื่อ",
      defaultValue: "",
      type: "text",
      disabled: true,
    },
    {
      id: 2,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "lastName",
      label: "นามสกุล",
      defaultValue: "",
      type: "text",
      disabled: true,
    },
    {
      id: 3,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "tel",
      label: "เบอร์โทรศัพท์",
      defaultValue: "",
      type: "tel",
      disabled: true,
    },
    {
      id: 4,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "cid",
      label: "เลขบัตรประชาชน",
      defaultValue: "",
      type: "text",
      disabled: true,
    },
    {
      id: 5,
      rowWidth: { xs: 12 },
      control: control,
      name: "address",
      label: "ที่อยู่ตามสำเนาทะเบียนบ้าน",
      defaultValue: "",
      type: "text",
      multiline: true,
      rows: 3,
      extended: true,
      disabled: true,
    },
  ];

  const rentingInforms = [
    {
      id: 1,
      rowWidth: implementMachines.length === 1 ? { xs: 12, md: 6 } : { xs: 12 },
      control: control,
      name: "model",
      label: "รุ่นสินค้า",
      defaultValue: "",
      type: "text",
      disabled: true,
      ...(implementMachines.length !== 1 && {
        extended: true,
      }),
    },
    ...(implementMachines.length > 0
      ? implementMachines.map((_, idx) => ({
          id: `imp_${idx + 1}`,
          rowWidth: { xs: 12, md: 6 },
          control: control,
          name: `implement_${idx + 1}`,
          label: `อุปกรณ์ต่อพ่วง ${idx + 1}`,
          defaultValue: "",
          type: "text",
          disabled: true,
        }))
      : []),
    {
      id: 3,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "VIN",
      label: "หมายเลขรถ",
      defaultValue: "",
      type: "text",
      disabled: true,
    },
    {
      id: 4,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "engineNo",
      label: "หมายเลขเครื่องยนต์",
      defaultValue: "",
      type: "text",
      disabled: true,
    },
    {
      id: 5,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "licenseNo",
      label: "หมายเลขทะเบียน",
      defaultValue: "",
      type: "text",
      disabled: true,
    },
    {
      id: 6,
      dateTime: true,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "startDate",
      label: "วันที่เริ่มต้น",
      defaultValue: new Date(),
      onChangeInputDate: onChangeStartDate,
      shouldDisableDate: disableCalendarDate(startDate),
      disabled:
        checkRole({ role: "ad", upn }) ||
        isAfter(new Date(), addDays(startDate, MAX_PRE_RESERVED_DAYS)),
    },
    {
      id: 7,
      dateTime: true,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "endDate",
      label: "วันที่คืนรถ",
      defaultValue: new Date(),
      disabled: true,
    },
    {
      id: 8,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "rentDays",
      label: "ระยะเวลาเช่า",
      defaultValue: "",
      type: "text",
      disabled: true,
    },
    {
      id: 9,
      rowWidth: { xs: 12 },
      control: control,
      name: "address_del",
      label: "สถานที่ส่ง/รับคืนรถ",
      defaultValue: "",
      type: "text",
      multiline: true,
      rows: 3,
      extended: true,
      disabled: true,
    },
  ];

  const canDelivery =
    (bookingStatus.client === CLIENT_STATUS.PAID1.nameEN ||
      bookingStatus.client === CLIENT_STATUS.ACTIVE.nameEN ||
      bookingStatus.client === CLIENT_STATUS.PAID2.nameEN ||
      bookingStatus.client === CLIENT_STATUS.COMPLETED.nameEN) &&
    bookingStatus.ad === AD_STATUS.WAITING.nameEN &&
    contractADWithKIN.filter((contract) => isUrl(contract.data)).length > 0 &&
    adContractValue.data &&
    contractKINWithClient.filter((contract) => isUrl(contract.data)).length >
      0 &&
    clientContractValue.data;
  const deliveryButton = canDelivery && (
    <Button
      onClick={submitUpdateMachineStatus(AD_STATUS.DELIVERY.nameEN)}
      variant="contained"
      color="primary"
      sx={{ ml: 2 }}
    >
      <LocalShippingIcon sx={{ mr: 1 }} />
      ส่งมอบเครื่องจักร
    </Button>
  );

  const canReturn =
    bookingStatus.client === CLIENT_STATUS.COMPLETED.nameEN &&
    bookingStatus.ad === AD_STATUS.COMPLETED.nameEN;
  const returnButton = canReturn && (
    <Button
      onClick={submitUpdateMachineStatus(AD_STATUS.RETURN.nameEN)}
      variant="contained"
      color="primary"
      sx={{ ml: 2 }}
    >
      <ReplyIcon sx={{ mr: 1 }} />
      รับคืนเครื่องจักร
    </Button>
  );

  return (
    <Fragment>
      <Box component="section" sx={{ pl: 4, pr: 4 }}>
        <Grid container mt={1} mb={2}>
          {/* Header */}
          <Grid container item xs={12} mb={1}>
            <Typography variant="h6">
              <strong>เลขที่การเช่า {bookingNo}</strong>
            </Typography>
          </Grid>

          <Form
            onSubmit={handleSubmit(submitBookingData, onErrorSubmitBookingData)}
          >
            <Grid container item xs={12}>
              {/* Section 1 */}
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Typography variant="subtitle1" ml={2}>
                  (1) รายละเอียดลูกค้า
                </Typography>
              </Grid>
              {userInforms.map(({ rowWidth, ...formProps }) => (
                <Grid key={formProps.id} item mb={2} {...rowWidth}>
                  <InlineInput {...formProps} />
                </Grid>
              ))}
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Typography variant="subtitle1" ml={2}>
                  (2) รายละเอียดการเช่า
                </Typography>
              </Grid>
              {rentingInforms.map(({ rowWidth, ...formProps }) => (
                <Grid key={formProps.id} item mb={2} {...rowWidth}>
                  {formProps.dateTime ? (
                    <InlineDatePicker {...formProps} />
                  ) : (
                    <InlineInput {...formProps} />
                  )}
                </Grid>
              ))}
            </Grid>
            <Divider sx={{ backgroundColor: "#978F8F", mb: 2 }} />
            <Grid container item xs={12}>
              <Grid item xs={12} sx={{ mb: 1 }}>
                <Typography variant="subtitle1" ml={2}>
                  (3) ค่าเช่าเครื่องจักร และ ค่าขนส่งเครื่องจักร
                </Typography>
              </Grid>
              {/* <Grid item xs={12} md={6} mb={2}>
                <InlineInput
                  control={control}
                  name="price"
                  defaultValue=""
                  label="ค่าเช่า"
                  text="text"
                  disabled={true}
                />
              </Grid> */}
              <Grid item xs={12} mb={1} pl={{ xs: 0, md: 5 }}>
                <List
                  disablePadding
                  sx={(theme) => ({
                    border: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: theme.spacing(0.5),
                  })}
                >
                  <ListItem
                    sx={{
                      bgcolor: (theme) => theme.palette.grey[50],
                    }}
                  >
                    <ListItemText>
                      <strong>ค่าเช่าเครื่องจักร</strong>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      {price.vin} บาท
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText>ส่วนลด</ListItemText>
                    <ListItemSecondaryAction>
                      {price.vinDiscount} บาท
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem sx={{ bgcolor: (theme) => theme.palette.grey[50] }}>
                    <ListItemText>
                      <strong>ค่าขนส่งเครื่องจักร</strong>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      {price.shipping} บาท
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem>
                    <ListItemText>ส่วนลด</ListItemText>
                    <ListItemSecondaryAction>
                      {price.shippingDiscount} บาท
                    </ListItemSecondaryAction>
                  </ListItem>
                  <ListItem
                    sx={{ bgcolor: (theme) => theme.palette.grey[300] }}
                  >
                    <ListItemText>
                      <strong>รวมทั้งหมด</strong>
                    </ListItemText>
                    <ListItemSecondaryAction>
                      {price.total.toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      บาท
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>

                {price.totalAmount > 0 &&
                (checkRole({ role: "kin", upn }) ||
                  checkRole({ role: "skc", upn })) ? (
                  <List>
                    <ListItem
                      sx={(theme) => ({
                        bgcolor: theme.palette.primary.light,
                        borderRadius: theme.spacing(0.5),
                        border: `1px solid ${theme.palette.primary.main}`,
                      })}
                    >
                      <ListItemText>
                        <strong>KIN ได้รับ</strong>
                      </ListItemText>
                      <ListItemSecondaryAction>
                        {(price.totalAmount - price.total).toLocaleString(
                          "en-US",
                          { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                        )}{" "}
                        บาท
                      </ListItemSecondaryAction>
                    </ListItem>
                  </List>
                ) : null}
              </Grid>
              <Grid item xs={12} mb={2} pl={{ xs: 0, md: 5 }}>
                <Typography component="small" variant="subtitl2">
                  หมายเหตุ
                </Typography>
                <Typography
                  component="small"
                  variant="subtitl2"
                  sx={{ display: "block", textIndent: 5 }}
                >
                  1. ค่าเช่าเครื่องจักรหลังหักค่าบริการ 15%
                  และรวมภาษีมูลค่าเพิ่ม (VAT)
                </Typography>
                <Typography
                  component="small"
                  variant="subtitl2"
                  sx={{ display: "block", textIndent: 5 }}
                >
                  2. ค่าเช่าเครื่องจักรและค่าขนส่งเครื่องจักรยังไม่รวมการหักภาษี
                  ณ ที่จ่าย
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={{ backgroundColor: "#978F8F", mb: 2 }} />
            <Grid container item xs={12}>
              <Grid
                container
                item
                alignItems="center"
                xs={12}
                md={3}
                lg={2}
                sx={{ mr: { xs: 0, md: 2 }, mb: 2 }}
              >
                <Typography variant="subtitle1" ml={2}>
                  (4) เอกสารสัญญาเช่า
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={4} mb={2}>
                <Accordion
                  expanded={expandedProcedures}
                  onChange={toggleExpandProcedures}
                  disableGutters={true}
                  sx={{
                    backgroundColor: "info.light",
                    color: "common.white",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      <ExpandMoreIcon sx={{ color: "common.white" }} />
                    }
                    aria-controls="upload-procedures-content"
                    id="upload-procedures-header"
                  >
                    <Typography>วิธีการดำเนินการ</Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      backgroundColor: "common.white",
                      color: "common.black",
                    }}
                  >
                    <Typography component="p" variant="caption">
                      1. กดดาวน์โหลดและลงนามในสัญญาเช่า
                    </Typography>
                    <Typography component="p" variant="caption">
                      2. กดดาวน์โหลดและดำเนินการให้ลูกค้าผู้เช่าลงนามในสัญญา
                    </Typography>
                    <Typography component="p" variant="caption" mb={1}>
                      3. อัพโหลดสัญญาเช่าทั้ง 2 ฉบับที่ได้รับการลงนามให้ครบถ้วน
                    </Typography>
                    <Typography
                      component="p"
                      align="center"
                      variant="caption"
                      color="warning.main"
                    >
                      *** ต้องอัพโหลดสัญญาเช่าทั้ง 2
                      ฉบับให้เสร็จสิ้นก่อนจึงจะสามารถอัพเดตสถานะ ส่งเครื่องจักร
                      ได้ ***
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid container item alignItems="center" xs={12} mb={2}>
                <Typography variant="subtitle2" mx={2}>
                  สัญญาเช่า 1: สัญญาเช่าระหว่างผู้แทนจำหน่ายและบริษัทเกษตรอินโน
                </Typography>
                <Fab
                  variant="extended"
                  size="small"
                  color="primary"
                  onClick={onClickDownloadContract("adcontract")}
                  sx={{ ml: { xs: 3, md: 0 }, mt: { xs: 2, md: 0 } }}
                >
                  <FileDownloadIcon sx={{ mr: 0.5 }} />
                  ดาวน์โหลดสัญญาเช่า 1
                </Fab>
              </Grid>
              <Grid item xs={12}>
                <FileInput
                  ref={contractADWithKINRef}
                  name="contractADWithKIN"
                  control={control}
                  defaultValue=""
                  label="สัญญาเช่า 1"
                  size="medium"
                  helperMessage="เลือก ไฟล์สัญญาเช่าที่ลงนามเรียบร้อย (ขนาดไฟล์ไม่เกิน 5 MB และเฉพาะสกุลไฟล์ .pdf  เท่านั้น)"
                  type="file"
                  accept="application/pdf"
                  value={
                    !contractADWithKIN?.at(-1)?.flag_delete &&
                    contractADWithKIN.at(-1)
                  }
                  onSelectHandler={onSelectContract}
                  onRemoveHandler={onRemoveContract}
                  onShowHandler={openModalPreviewContract}
                  inputLength={{
                    label: { xs: "40%", md: "30%", lg: "25%" },
                    input: { xs: "60%", md: "70%", lg: "75%" },
                  }}
                />
              </Grid>
              <Grid container item alignItems="center" xs={12} my={2}>
                <Typography variant="subtitle2" mx={2}>
                  สัญญาเช่า 2: สัญญาเช่าระหว่างบริษัทเกษตรอินโนและลูกค้าผู้เช่า
                </Typography>
                <Fab
                  variant="extended"
                  size="small"
                  color="primary"
                  onClick={onClickDownloadContract("cuscontract")}
                  sx={{ ml: { xs: 3, md: 0 }, mt: { xs: 2, md: 0 } }}
                >
                  <FileDownloadIcon sx={{ mr: 0.5 }} />
                  ดาวน์โหลดสัญญาเช่า 2
                </Fab>
              </Grid>
              <Grid item xs={12} mb={1}>
                <FileInput
                  ref={contractKINWithClientRef}
                  name="contractKINWithClient"
                  control={control}
                  defaultValue=""
                  label="สัญญาเช่า 2"
                  size="medium"
                  helperMessage="เลือก ไฟล์สัญญาเช่าที่ลงนามเรียบร้อย (ขนาดไฟล์ไม่เกิน 5 MB และเฉพาะสกุลไฟล์ .pdf  เท่านั้น)"
                  type="file"
                  accept="application/pdf"
                  value={
                    !contractKINWithClient?.at(-1)?.flag_delete &&
                    contractKINWithClient.at(-1)
                  }
                  onSelectHandler={onSelectContract}
                  onRemoveHandler={onRemoveContract}
                  onShowHandler={openModalPreviewContract}
                  inputLength={{
                    label: { xs: "40%", md: "30%", lg: "25%" },
                    input: { xs: "60%", md: "70%", lg: "75%" },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} mb={4} justifyContent="flex-end">
              <Button type="submit" variant="contained" color="secondary">
                บันทึกเอกสารสัญญา
              </Button>
              {deliveryButton}
              {returnButton}
            </Grid>
          </Form>
        </Grid>
      </Box>

      <Modal
        open={previewContract.contractADWithKIN}
        onClose={closeModalPreviewContract("contractADWithKIN")}
        titleWithClose
        dialogTitle={contractADWithKIN?.at(-1)?.filename}
        fullScreen
        sx={{ overflow: "hidden" }}
      >
        <IFrameDoc
          src={contractADWithKIN?.at(-1)?.data}
          title="contractADWithKIN__pdf"
        />
      </Modal>

      <Modal
        open={previewContract.contractKINWithClient}
        onClose={closeModalPreviewContract("contractKINWithClient")}
        titleWithClose
        dialogTitle={contractKINWithClient?.at(-1)?.filename}
        fullScreen
        sx={{ overflow: "hidden" }}
      >
        <IFrameDoc
          src={contractKINWithClient?.at(-1)?.data}
          title="contractKINWithClient__pdf"
        />
      </Modal>

      <Backdrop
        open={isSending || sendHttpSuccess.status}
        sx={(theme) => ({
          color: theme.palette.primary.main,
          zIndex: theme.zIndex.drawer + 1,
          opacity: 1,
          transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        })}
      >
        <CircularProgress color="inherit" size="5rem" />
      </Backdrop>

      <Alert
        position={{ vertical: "top", horizontal: "center" }}
        open={sendHttpSuccess.status}
        hideDuration={2000}
        onClose={() => {
          onToggleAlertBar("", false);
        }}
        status="success"
        message={sendHttpSuccess.message}
      />
    </Fragment>
  );
};

export default BookingDetailPage;
