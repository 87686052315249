import PropTypes from "prop-types";

import {
  Paper as MuiPaper,
  Grid,
  Typography,
  Chip,
  Fab as MuiFab,
} from "@mui/material";
import { FindInPage as PageDetailIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import { STATUS } from "constants/Machine";

const Paper = styled(MuiPaper)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2),
  backgroundColor: "#F9F9F9",
  border: `1px solid ${theme.palette.primary.main}`,
  marginBottom: theme.spacing(3),
}));

const ProductMainImage = styled("img", {
  shouldForwardProp: (prop) => prop !== "hasOthersImages",
})(({ theme, hasOthersImages }) => ({
  width: hasOthersImages ? "80%" : "60%",
  [theme.breakpoints.up("md")]: {
    width: hasOthersImages ? "100%" : "80%",
  },
  objectFit: "contain",
}));

const ColumnImages = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  "& img": {
    objectFit: "contain",
    width: "100%",
    justifySelf: "center",
    alignSelf: "center",
    "&:not(:last-child)": {
      marginBottom: theme.spacing(0.5),
    },
  },
}));

const StatusGrid = styled(Grid)(({ theme }) => ({
  justifyContent: "flex-start",
  alignItems: "center",
  [theme.breakpoints.up("md")]: {
    flexDirection: "column",
    alignItems: "flex-end",
  },
}));

const Fab = styled(MuiFab)(({ theme }) => ({
  height: theme.spacing(4),
  width: theme.spacing(17),
}));

const MachineList = ({
  VIN,
  mainImage,
  otherImages,
  nameTH,
  model,
  status,
  workingHours,
  year,
  rentalTimes,
  onClickSeeDetail,
}) => {
  const productData = [
    {
      id: 1,
      label: nameTH,
      content: `รุ่น ${model}`,
    },
    { id: 2, label: "ปีสินค้า", content: year || "N/A" },
    {
      id: 3,
      label: "ชั่วโมงการใช้งาน",
      content: `${workingHours} ชั่วโมง`,
    },
    {
      id: 4,
      label: "หมายเลขรถ",
      content: VIN,
    },
    {
      id: 5,
      label: "จำนวนครั้งในการเช่า",
      content: `${rentalTimes} ครั้ง`,
    },
  ];

  const onClickFabDetail = (vehicleId) => () => {
    onClickSeeDetail(vehicleId);
  };

  const hasOthersImages = otherImages.length > 0;

  return (
    <Paper elevation={0}>
      <Grid container item alignItems="center" xs={12}>
        <Grid
          container
          item
          xs={12}
          md={4}
          sx={{
            justifyContent: {
              xs: "center",
              md: hasOthersImages ? "flex-start" : "center",
            },
          }}
        >
          <Grid
            container
            item
            justifyContent="center"
            alignItems="center"
            xs={hasOthersImages ? 7 : 12}
            mr={hasOthersImages ? 2 : 0}
          >
            <ProductMainImage
              hasOthersImages={hasOthersImages ? 1 : 0}
              src={mainImage}
              alt={`${nameTH}__main-img`}
            />
          </Grid>
          {hasOthersImages && (
            <Grid
              container
              item
              justifyContent="center"
              xs={2}
              sx={{
                alignItems: otherImages.length === 3 ? "center" : "flex-start",
                ...(otherImages.length < 3 && { mt: 1 }),
              }}
            >
              <ColumnImages>
                {otherImages.map((image, idx) => (
                  <img key={idx} src={image} alt={`${nameTH}__img`} />
                ))}
              </ColumnImages>
            </Grid>
          )}
        </Grid>

        <Grid
          container
          item
          xs={6}
          md={4}
          direction="column"
          sx={{ mx: { xs: "auto", md: 0 }, mb: { xs: 2, md: 0 }, pt: 0 }}
        >
          <Grid
            container
            item
            xs={12}
            sx={{
              "&.MuiGrid-root": {
                pt: { xs: 0.5, md: 0 },
                pb: 1,
              },
            }}
          >
            <Typography
              component="span"
              variant="secondary"
              sx={{ flexGrow: 1, fontSize: "18px" }}
            >
              <strong>เครื่องจักร</strong>
            </Typography>
            <Typography
              component="span"
              variant="secondary"
              sx={{ fontSize: "18px" }}
            >
              <strong>{nameTH}</strong>
            </Typography>
          </Grid>
          {productData.map((product) => (
            <Grid
              key={product.id}
              container
              item
              justifyContent="flex-start"
              xs={12}
            >
              <Typography component="span" variant="body2" sx={{ flexGrow: 1 }}>
                {product.label}
              </Typography>
              <Typography component="span" variant="body2">
                {product.content}
              </Typography>
            </Grid>
          ))}
        </Grid>

        <Grid
          container
          item
          xs={12}
          md={4}
          ml="auto"
          sx={{ "&.MuiGrid-root": { pl: 0 }, mb: { xs: 1, md: "auto" } }}
        >
          <StatusGrid container item>
            <Typography
              component="span"
              variant="caption"
              ml={2}
              mr={1}
              mb={{ xs: 0, md: 0.5 }}
            >
              สถานะเครื่องจักร
            </Typography>
            <Chip
              label={STATUS[status.toUpperCase()].nameTH}
              sx={{
                borderRadius: 0,
                ...STATUS[status.toUpperCase()].styles,
              }}
            />
            <Fab
              variant="extended"
              onClick={onClickFabDetail(VIN)}
              sx={{
                ml: { xs: "auto", md: 0 },
                mt: { xs: 0, md: 2 },
                mr: { xs: 2, sm: 0 },
              }}
            >
              รายละเอียด
              <PageDetailIcon sx={{ ml: 1 }} />
            </Fab>
          </StatusGrid>
        </Grid>
      </Grid>
    </Paper>
  );
};

MachineList.propTypes = {
  images: PropTypes.array,
  nameTH: PropTypes.node,
  model: PropTypes.string,
  status: PropTypes.string,
  serialNo: PropTypes.string,
  workingHours: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rentalTimes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

MachineList.defaultProps = {
  images: [],
  status: "draft",
};

export default MachineList;
