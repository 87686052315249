import {
  Fragment,
  useState,
  useReducer,
  useRef,
  useContext,
  forwardRef,
} from "react";
import { useForm } from "react-hook-form";
import XLSX from "xlsx";

import {
  Grid,
  Typography,
  Button,
  Alert as MuiAlert,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

import AuthContext from "contexts/auth/auth-context";
import { useHttp } from "hooks";
import { InlineSelectInput, FileInput } from "components/HookForms";
import { Alert } from "components";

const initialTableData = {
  title: "",
  columns: [],
  rows: [],
  alert: {
    status: false,
    message: "",
  },
};

const tableDataReducer = (state, action) => {
  if (action.type === "RENDER_DOWNLOADED_DATA") {
    const columns = Object.entries(action.data[0]).map(([column]) => ({
      field: column,
      headerName: column,
      flex: 1,
    }));
    const rows = action.data.map((row, idx) => {
      let newRow = { id: idx + 1 };
      for (const column of columns) {
        newRow = { ...newRow, [column.field]: row[column.headerName] };
      }
      return newRow;
    });
    if (action.callbackFn) action.callbackFn();
    return { ...state, columns, rows, title: "ข้อมูลสำหรับดาวน์โหลด" };
  }

  if (action.type === "ONCHANGE_FILE") {
    const columns = action.columns.map((column) => ({
      field: column,
      headerName: column,
      flex: 1,
      editable: true,
    }));
    const rows = action.rows.map((row, idx) => ({
      id: idx + 1,
      ...row,
    }));

    /* Check column upload file contains column master file  */
    let alert;
    const duplicatedColumns = [];
    for (const column of columns) {
      for (const existingColumn of state.columns) {
        if (column.field === existingColumn.field) {
          duplicatedColumns.push(column);
        }
      }
    }
    if (duplicatedColumns.length !== state.columns.length) {
      alert = {
        status: true,
        message: `ต้องระบุชื่อคอลัมน์ ที่เหมือนกับหรือมากกว่าไฟล์ข้อมูลหลัก *ประเภทข้อมูล: ${action.masterType}*`,
      };

      if (action.callbackFn) action.callbackFn();
      return { ...state, alert };
    }
    /* End Checking */

    return { ...state, columns, rows, title: "ข้อมูลสำหรับอัพโหลด" };
  }

  if (action.type === "CLOSE_ALERT")
    return {
      ...state,
      alert: {
        status: false,
        message: "",
      },
    };

  return state;
};

const Form = styled("form")({ width: "100%" });

const SnackBar = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={2} ref={ref} variant="filled" {...props} />;
});

const DataGridContainer = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(1),
  height: "70vh",
  width: "100%",
}));

const MasterDataPage = (props) => {
  const [table, dispatchTableAction] = useReducer(
    tableDataReducer,
    initialTableData
  );
  // const [delayOnChangeFunc, setDelayOnChangeFunc] = useState(null);
  const [masterUploadedFile, setMasterUploadedFile] = useState({});
  const [sendHttpSuccess, setSendHttpSuccess] = useState({
    message: "",
    status: false,
    hideDuration: 0,
  });
  const { idToken, accessToken } = useContext(AuthContext);
  const fileInputRef = useRef(null);

  const {
    control: controlDownload,
    getValues: getDownloadValue,
    // setValue: setDownloadValue,
    handleSubmit: onDownloadFile,
  } = useForm();
  const {
    control: controlUpload,
    setValue: setUploadValue,
    handleSubmit: onUploadFile,
  } = useForm();

  const masterType = getDownloadValue("masterType");

  const { isLoading, sendRequest: fetchMasterData } = useHttp();
  const { isLoading: isSending, sendRequest: sendUpdateRequest } = useHttp();

  const onSelectMasterDataType = (selectType) => {
    const [type] = selectType.split(" ");

    const setTableData = (data) => {
      dispatchTableAction({
        type: "RENDER_DOWNLOADED_DATA",
        data,
        callbackFn: () =>
          setMasterUploadedFile(() => {
            setUploadValue("masterUploadedFile", null);
            return "";
          }),
      });
    };

    fetchMasterData(
      {
        endpoint: `/skc/${type}`,
        headers: { id_token: idToken, access_token: accessToken },
      },
      setTableData
    );

    // if (delayOnChangeFunc) {
    //   clearTimeout(delayOnChangeFunc);
    //   setDelayOnChangeFunc(null);
    // }
    // setDelayOnChangeFunc(
    //   setTimeout(() => {
    //     fetchMasterData({ endpoint: `/skc/${type}` }, setTableData);
    //   }, 1000)
    // );
  };

  const onSelectExcelFile = (e) => {
    // setDownloadValue("masterType", "");

    const [file] = e.target.files;
    setMasterUploadedFile({ filename: file.name });
    const reader = new FileReader();
    reader.onload = (e) => {
      /* Parse data */
      const ab = e.target.result;
      const wb = XLSX.read(ab, { type: "array" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      const columns = data[0].map((column) => column);
      const rows = XLSX.utils.sheet_to_json(ws);
      dispatchTableAction({
        type: "ONCHANGE_FILE",
        columns,
        rows,
        masterType,
        callbackFn: onRemoveExcelFile,
      });
    };
    reader.readAsArrayBuffer(file);
  };

  const onRemoveExcelFile = () => {
    fileInputRef.current.value = null;
    setMasterUploadedFile(() => {
      setUploadValue("masterUploadedFile", null);
      return "";
    });

    const [type] = masterType.split(" ");
    const setTableData = (data) => {
      dispatchTableAction({
        type: "RENDER_DOWNLOADED_DATA",
        data,
      });
    };

    fetchMasterData(
      {
        endpoint: `/skc/${type}`,
        headers: { id_token: idToken, access_token: accessToken },
      },
      setTableData
    );
  };

  const onDownloadMasterData = (formData) => {
    // console.log("table: ", table);
    console.log("formData: ", formData);
    // const parseJSON = JSON.parse(formData);
    // const worksheet = XLSX.ultils.json_to_sheet(parseJSON);
    // console.log("worksheet: ", worksheet);
    // const workbook = XLSX.ultils.book_new();
    // console.log("workbook: ", workbook);
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    // XLSX.writeFile(workbook, "DataSheet.xlsx");
    // return;
  };

  const onUploadMasterData = (formData) => {
    const [type] = masterType.split(" ");

    const setTableData = (data) => {
      setSendHttpSuccess({
        status: true,
        message: `อัพโหลดไฟล์ ${masterUploadedFile.filename} เรียบร้อยแล้ว`,
        hideDuration: 4000,
      });
      dispatchTableAction({
        type: "RENDER_DOWNLOADED_DATA",
        data,
        callbackFn() {
          fileInputRef.current.value = null;
          setMasterUploadedFile(() => {
            setUploadValue("masterUploadedFile", null);
            return "";
          });
        },
      });
    };

    sendUpdateRequest(
      {
        endpoint: `/skc/${type}`,
        method: "POST",
        body: table.rows,
        headers: { id_token: idToken, access_token: accessToken },
      },
      setTableData
    );
  };

  const InformedWording =
    !isLoading && !table.rows.length && !table.columns.length ? (
      <Grid container item xs={12} md={8} mb={2} sx={{ flexGrow: 1 }}>
        <SnackBar
          severity="info"
          sx={{ width: { xs: "100%", md: "75%", lg: "50%" } }}
        >
          เลือก ประเภทข้อมูล เพื่อแสดงตารางข้อมูลหลัก
        </SnackBar>
      </Grid>
    ) : null;

  const loadingElement = isLoading ? (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: "30vh" }}
    >
      <CircularProgress color="primary" size="5rem" />
    </Grid>
  ) : null;

  const downloadFileButton =
    !isLoading && masterType && !masterUploadedFile?.filename ? (
      <Button
        variant="contained"
        color="secondary"
        type="submit"
        sx={{ mx: { xs: "auto", md: 0 } }}
      >
        ดาวน์โหลด
      </Button>
    ) : null;

  const uploadFileInput =
    !isLoading && masterType ? (
      <Form onSubmit={onUploadFile(onUploadMasterData)}>
        <Grid container>
          <Grid item xs={9}>
            <FileInput
              ref={fileInputRef}
              name="masterUploadedFile"
              control={controlUpload}
              defaultValue=""
              label="ไฟล์ข้อมูลหลัก"
              helperMessage="เฉพาะไฟล์สกุล .xls, .xlsx เท่านั้น"
              type="file"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              value={masterUploadedFile}
              onSelectHandler={onSelectExcelFile}
              onRemoveHandler={onRemoveExcelFile}
              // onShowHandler={openModalPreviewContract}
              inputLength={{
                label: { xs: "40%", md: "30%", lg: "25%" },
                input: { xs: "60%", md: "70%", lg: "75%" },
              }}
            />
          </Grid>
          <Grid
            item
            xs={3}
            md="auto"
            pl={2}
            // pl={{ xs: 0, md: 2 }}
            // pt={{ xs: 2, md: 0 }}
          >
            {masterUploadedFile?.filename ? (
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ mx: { xs: "auto", md: 0 } }}
              >
                อัพโหลด
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Form>
    ) : null;

  const dataGridElement =
    !isLoading && table.rows.length > 0 && table.columns.length > 0 ? (
      <Grid container direction="column" alignItems="center" mt={0.5}>
        <Grid item>
          <Typography
            variant="subtitle2"
            color={table.title.includes("ดาวน์โหลด") ? "secondary" : "primary"}
          >
            <u>{table.title}</u>
          </Typography>
        </Grid>
        <DataGridContainer>
          <DataGrid columns={table.columns} rows={table.rows} />
        </DataGridContainer>
      </Grid>
    ) : null;

  return (
    <Fragment>
      <Grid container mt={1}>
        <Form onSubmit={onDownloadFile(onDownloadMasterData)}>
          <Grid container item xs={12}>
            <Grid
              container
              item
              alignItems="center"
              xs={12}
              md={InformedWording ? "auto" : 12}
              mr={3}
              mb={2}
            >
              <Typography variant="h6">
                <strong>จัดการข้อมูลหลัก</strong>
              </Typography>
            </Grid>
            {/* Initial render element */}
            {InformedWording}
            <Grid item xs={9} mt={1} mb={2}>
              <InlineSelectInput
                control={controlDownload}
                name="masterType"
                label="ประเภทข้อมูล"
                defaultValue=""
                subheaderName="เลือก รูปแบบฟอร์ม"
                menuItems={["product master", "dealer master", "price master"]}
                disabled={false}
                inputLength={{
                  label: { xs: "40%", md: "30%", lg: "25%" },
                  input: { xs: "60%", md: "70%", lg: "75%" },
                }}
                onChangeSelect={onSelectMasterDataType}
              />
            </Grid>
            <Grid
              container
              item
              xs={3}
              md="auto"
              alignItems="center"
              pl={2}
              // pt={{ xs: 2, md: 0 }}
            >
              {downloadFileButton}
            </Grid>
          </Grid>
        </Form>

        {/* Fetching new data */}
        {loadingElement}

        {/* Datagrid when finished fetch or upload excel file successfully */}
        {uploadFileInput}
        {dataGridElement}
      </Grid>

      <Alert
        position={{ vertical: "top", horizontal: "center" }}
        open={table.alert.status}
        hideDuration={6000}
        status="error"
        message={table.alert.message}
        onClose={() => dispatchTableAction({ type: "CLOSE_ALERT" })}
      />

      <Backdrop
        open={isSending}
        sx={(theme) => ({
          color: theme.palette.primary.main,
          zIndex: theme.zIndex.drawer + 1,
          opacity: 1,
          transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        })}
      >
        <CircularProgress color="inherit" size="5rem" />
      </Backdrop>

      <Alert
        position={{ vertical: "top", horizontal: "center" }}
        open={sendHttpSuccess.status}
        hideDuration={sendHttpSuccess.hideDuration}
        status="success"
        message={sendHttpSuccess.message}
        onClose={() =>
          setSendHttpSuccess({ status: false, message: "", hideDuration: 0 })
        }
      />
    </Fragment>
  );
};

export default MasterDataPage;
