import { Fragment, useState, useEffect, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Grid, Paper, Typography, Button, Link, Collapse } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  AddCircleOutline as AddCircleOutlineIcon,
  FilterAlt as FilterIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from "@mui/icons-material";

import AuthContext from "contexts/auth/auth-context";

import { Spinner } from "components";

import { useHttp } from "hooks";
import MachineList from "./components/MachineList";
import { SelectInput } from "components/HookForms";
import { yearOptions } from "helpers/DateTime";
import { COUNTDOWN_YEAR } from "constants/DateTime";
import { STATUS } from "constants/Machine";

const FilterFrame = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "elevation",
})(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.grey[100],
}));

const MachineListPage = (props) => {
  const [showFilter, setShowFilter] = useState(true);
  const [machines, setMachines] = useState([]);
  const [machineTypes, setMachineTypes] = useState([]);
  const [machineModels, setMachineModels] = useState([]);
  const [sortingMachine, setSortingMachine] = useState({
    registeredDate: "asc",
    status: "asc",
  });
  const { idToken, accessToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const { control, setValue, getValues, watch } = useForm();

  const { isLoading: isLoadingMachines, sendRequest: fetchMachinesList } =
    useHttp();
  const { isLoading: isLoadingTypes, sendRequest: fetchMachineTypes } =
    useHttp();
  const { isLoading: isFiltering, sendRequest: filterMachineList } = useHttp();

  const model = getValues("model");

  const machineStatus = useMemo(() => {
    const statusOptions = [];
    for (let value of Object.values(STATUS)) {
      statusOptions.push(value);
    }
    return statusOptions;
  }, []);

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const setResponseMachineLists = (machines) => {
      const modMachinesStatus = machines.map((machine) => {
        return {
          ...machine,
          status: {
            nameEN: machine.status,
            order: STATUS[machine.status?.toUpperCase()]?.order,
          },
        };
      });
      setMachines(modMachinesStatus);
    };
    const setResponseMachineTypes = ({ machines }) => {
      const typeOptions = machines.map((type, idx) => ({
        id: idx + 1,
        ...type,
      }));
      setMachineTypes(typeOptions);
    };

    const fetchHttpMachinesList = (signal) => {
      fetchMachinesList(
        {
          endpoint: "/machine/id",

          headers: { id_token: idToken, access_token: accessToken },
          signal,
        },
        setResponseMachineLists
      );
    };
    const fetchHttpMachineTypes = (signal) => {
      fetchMachineTypes(
        {
          endpoint: "/machine/type",
          headers: { id_token: idToken, access_token: accessToken },
          signal,
        },
        setResponseMachineTypes
      );
    };

    fetchHttpMachinesList(signal);
    fetchHttpMachineTypes(signal);

    return () => abortCont.abort();
  }, [fetchMachinesList, fetchMachineTypes, idToken, accessToken]);

  useEffect(() => {
    const subscription = watch((data) => {
      const queryStringParams = [];
      for (let [key, value] of Object.entries(data)) {
        if (value && value !== "all" && !queryStringParams.length) {
          queryStringParams.push(`?${key}=${value}`);
          continue;
        }
        if (value && value !== "all" && queryStringParams.length > 0) {
          queryStringParams.push(`&${key}=${value}`);
        }
      }
      filterMachineList(
        {
          endpoint: `/machine/id${queryStringParams.join("")}`,
          headers: { id_token: idToken, access_token: accessToken },
        },
        (filteredMachines) => {
          const modMachinesStatus = filteredMachines.map((machine) => {
            return {
              ...machine,
              status: {
                nameEN: machine.status,
                order: STATUS[machine.status.toUpperCase()].order,
              },
            };
          });
          setMachines(modMachinesStatus);
        }
      );
    });

    return () => subscription.unsubscribe();
  }, [watch, filterMachineList, idToken, accessToken]);

  const navigateToAddMachinePage = () => {
    navigate("./add");
  };

  const toggleFilterFormGroup = () => {
    setShowFilter((prevShowFilter) => !prevShowFilter);
  };

  const onChangeMachineType = (value) => {
    if (value && value !== "all")
      setMachineModels(() => {
        model !== "all" && setValue("model", "all");
        const modelOptions = machineTypes.find((type) => type.nameTH === value);
        return modelOptions?.model || [];
      });
  };

  const onSortingMachine = (field) => () => {
    /* Guard Clause */
    if (!field) return;

    setSortingMachine((prevSort) => {
      setMachines((prevMachines) => {
        /* DESC */
        if (prevSort[field] === "asc") {
          const descMachines = prevMachines.sort((a, b) => {
            if (field === "status") return b.status.order - a.status.order;
            if (field === "registeredDate")
              return (
                Date.parse(b.registeredDate) - Date.parse(a.registeredDate)
              );
            return prevMachines;
          });
          // console.log("desc: ", descMachines);
          return descMachines;
        }
        /* ASC */
        if (prevSort[field] === "desc") {
          const ascMachines = prevMachines.sort((a, b) => {
            if (field === "status") return a.status.order - b.status.order;
            if (field === "registeredDate")
              return (
                Date.parse(a.registeredDate) - Date.parse(b.registeredDate)
              );
            return prevMachines;
          });
          // console.log("asc: ", ascMachines);
          return ascMachines;
        }
      });
      return {
        ...prevSort,
        [field]: prevSort[field] === "asc" ? "desc" : "asc",
      };
    });
  };

  const navigateToUpdateMachinePage = (vehicleNo) => {
    navigate(`./update/${vehicleNo}`);
  };

  const onClickLinkToAddMachinePage = (e) => {
    e.preventDefault();
    navigate("./add");
  };

  const filterFormGroup = (
    <FilterFrame elevation={0}>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          px={1}
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: { mb: 1.5 },
          })}
        >
          <SelectInput
            name="type"
            label="ประเภทเครื่องจักร"
            control={control}
            defaultValue="all"
            firstMenuItem={{
              label: "ทั้งหมด",
              value: "all",
            }}
            menuProp="nameTH"
            valueProp="nameTH"
            menuItems={machineTypes}
            onChangeSelect={onChangeMachineType}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          px={1}
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: { mb: 1.5 },
          })}
        >
          <SelectInput
            name="model"
            label="รุ่นเครื่องจักร"
            control={control}
            defaultValue="all"
            firstMenuItem={{
              label: "ทั้งหมด",
              value: "all",
            }}
            menuItems={machineModels}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={3}
          px={1}
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: { mb: 1.5 },
          })}
        >
          <SelectInput
            name="year"
            label="ปีเครื่องจักร"
            control={control}
            defaultValue="all"
            firstMenuItem={{
              label: "ทั้งหมด",
              value: "all",
            }}
            menuItems={yearOptions(COUNTDOWN_YEAR)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3} px={1}>
          <SelectInput
            name="status"
            label="สถานะเครื่องจักร"
            control={control}
            defaultValue="all"
            firstMenuItem={{
              label: "ทั้งหมด",
              value: "all",
            }}
            menuProp="nameTH"
            valueProp="nameEN"
            menuItems={machineStatus}
          />
        </Grid>
      </Grid>
    </FilterFrame>
  );

  const machinesContent =
    isLoadingMachines || isLoadingTypes || isFiltering ? (
      // #Repalce with skeleton comps.
      <Spinner sx={{ mt: 6 }}>
        <Typography variant="h6">
          <strong>กำลังโหลดข้อมูล</strong>
        </Typography>
      </Spinner>
    ) : machines.length > 0 ? (
      <Fragment>
        <Grid container item xs={12} sm={6} lg={3} mb={0.5} ml="auto">
          <Button
            onClick={onSortingMachine("registeredDate")}
            color="secondary"
            endIcon={
              sortingMachine.registeredDate === "asc" ? (
                <ArrowDownwardIcon />
              ) : (
                <ArrowUpwardIcon />
              )
            }
            sx={{ ml: "auto" }}
          >
            วันที่ลงทะเบียน
          </Button>
          <Button
            onClick={onSortingMachine("status")}
            color="secondary"
            endIcon={
              sortingMachine.status === "asc" ? (
                <ArrowDownwardIcon />
              ) : (
                <ArrowUpwardIcon />
              )
            }
            sx={{ ml: "auto" }}
          >
            สถานะเครื่องจักร
          </Button>
        </Grid>
        {machines.map((machine) => (
          <MachineList
            key={machine.id}
            VIN={machine.VIN}
            mainImage={machine.imageLeft}
            otherImages={[
              machine.imageRight,
              machine.imageFront,
              machine.imageBack,
            ]}
            nameTH={machine.nameTH}
            model={machine.model}
            status={
              machine.status?.nameEN ? machine.status.nameEN : machine.status
            }
            workingHours={machine.hour}
            year={machine.year}
            rentalTimes={machine?.rentalTimes || 0}
            onClickSeeDetail={navigateToUpdateMachinePage}
          />
        ))}
      </Fragment>
    ) : (
      <Grid
        container
        item
        alignItems="center"
        direction="column"
        sx={{ mt: 4 }}
      >
        <Typography variant="h5">ไม่พบข้อมูลเครื่องจักร</Typography>
        <Typography variant="h6" sx={{ mt: 2 }}>
          เลือกตัวกรองใหม่ หรือ เริ่มลงทะเบียนเครื่องจักรใหม่{" "}
          <Link href="#" onClick={onClickLinkToAddMachinePage}>
            คลิกที่นี่
          </Link>
        </Typography>
      </Grid>
    );

  return (
    <Fragment>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        xs={12}
        mt={1}
        mb={2}
      >
        <Grid
          item
          xs={6}
          sx={(theme) => ({
            mr: { xs: "auto", md: 0 },
            flex: "1 0 100%",
            maxWidth: "100%",
            [theme.breakpoints.down("md")]: { mb: 1.5 },
          })}
        >
          <Typography variant="h6">
            <strong>รายการเครื่องจักรทั้งหมด</strong>
          </Typography>
        </Grid>
        <Grid item ml="auto">
          <Button
            onClick={navigateToAddMachinePage}
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            sx={(theme) => ({
              mr: 1.5,
              [theme.breakpoints.down("md")]: { mb: 1.5 },
            })}
          >
            ลงทะเบียนเครื่องจักรใหม่
          </Button>
          <Button
            onClick={toggleFilterFormGroup}
            variant="contained"
            startIcon={<FilterIcon />}
          >
            Filters
          </Button>
        </Grid>
      </Grid>

      <Collapse in={showFilter}>{filterFormGroup}</Collapse>

      {machinesContent}
    </Fragment>
  );
};

export default MachineListPage;
