import { useState, useEffect, useMemo, useCallback, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Typography, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import AuthContext from "contexts/auth/auth-context";
import { useHttp } from "hooks";

const BookingListPage = (props) => {
  const [bookingList, setBookingList] = useState([]);
  const { idToken, accessToken } = useContext(AuthContext);
  const navigate = useNavigate();

  const { sendRequest: fetchBookingsList } = useHttp();

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const setBookingData = (bookings) => {
      const bookingFormatted = bookings.map((booking, idx) => {
        return {
          id: Number(booking.book_no),
          book_no: booking.book_no,
          stage: booking.stage.wording,
          fullname: booking.name,
          rentDays: `${booking.rentDays} วัน`,
          startDate: booking.startdate,
          endDate: booking.enddate,
          adName: booking.ad_name || "N/A",
          detail: "",
        };
      });
      setBookingList(bookingFormatted);
    };

    const fetchHttp = (signal) => {
      fetchBookingsList(
        {
          endpoint: "/booking/id",
          headers: { id_token: idToken, access_token: accessToken },
          signal,
        },
        setBookingData
      );
    };

    fetchHttp(signal);

    return () => abortCont.abort();
  }, [fetchBookingsList, idToken, accessToken]);

  const onClickSeeDetail = useCallback(
    (bookingNo) => (e) => {
      e.stopPropagation();
      navigate(`./${bookingNo}`);
    },
    [navigate]
  );

  const columns = useMemo(() => {
    return [
      {
        field: "book_no",
        headerName: "เลขที่การเช่า",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "stage",
        headerName: "สถานะการเช่า",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "fullname",
        headerName: "ชื่อ-สกุล",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "rentDays",
        headerName: "ระยะเวลาเช่า",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "startDate",
        headerName: "วันที่เริ่มต้น",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "endDate",
        headerName: "วันที่สิ้นสุด",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "adName",
        headerName: "ผู้แทนจำหน่วย",
        flex: 1,
        minWidth: 100,
      },
      {
        field: "detail",
        headerName: "รายละเอียด",
        flex: 1,
        minWidth: 100,
        renderCell: ({ row }) => {
          return (
            <Button
              variant="contained"
              color="secondary"
              onClick={onClickSeeDetail(row.book_no)}
            >
              รายละเอียด
            </Button>
          );
        },
      },
    ];
  }, [onClickSeeDetail]);

  // const columns = useMemo(
  //   () => [
  //     { Header: "เลขที่การเช่า", accessor: "book_no" },
  //     { Header: "สถานะการเช่า", accessor: "stage" },
  //     { Header: "ชื่อ-สกุล", accessor: "fullname" },
  //     { Header: "ระยะเวลาการเช่า", accessor: "rentDays" },
  //     { Header: "วันที่เริ่มต้น", accessor: "startDate" },
  //     { Header: "วันที่สิ้นสุด", accessor: "endDate" },
  //     { Header: "ผู้แทนจำหน่วย", accessor: "adName" },
  //     {
  //       Header: "รายละเอียด",
  //       accessor: "detail",
  //       Cell: ({ row, ...props }) => {
  //         console.log("[Cell]row: ", row);
  //         console.log("[Cell]...props: ", props);
  //         return (
  //           <Button
  //             variant="contained"
  //             onClick={onClickSeeDetail(row.original.id)}
  //           >
  //             ดูเพิ่มเติม
  //           </Button>
  //         );
  //       },
  //     },
  //   ],
  //   [onClickSeeDetail]
  // );

  return (
    <Box component="section" sx={{ pl: 4, pt: 2, pr: 4 }}>
      <Typography variant="h6" mb={1}>
        <strong>สถานะการเช่าเครื่องจักรทั้งหมด</strong>
      </Typography>
      <div style={{ height: "80vh", width: "100%" }}>
        <DataGrid columns={columns} rows={bookingList} />
      </div>
      {/* <Table columns={columns} data={bookingList} /> */}
    </Box>
  );
};

export default BookingListPage;
