import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { Navigate } from "react-router-dom";

import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { loginRequest } from "config/msalAuthentication";

const Center = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "80vh",
});

const LogoutPage = (props) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const loginWithMsalHandler = () => {
    instance.loginRedirect(loginRequest);
  };

  if (isAuthenticated) return <Navigate to="/" replace={true} />;

  return (
    <Center>
      <Typography variant="h4" mb={4}>
        ออกจากระบบเรียบร้อยแล้ว
      </Typography>
      <Button onClick={loginWithMsalHandler} variant="contained">
        กลับเข้าสู่ระบบอีกครั้ง
      </Button>
    </Center>
  );
};

export default LogoutPage;
