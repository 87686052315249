import PropTypes from "prop-types";
import { useController } from "react-hook-form";
import th from "date-fns/locale/th";
import { LocalizationProvider, DesktopDatePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const InlineFormControl = styled(FormControl)(({ theme, size, extended }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiInputLabel-formControl": {
      display: "flex",
      justifyContent: "flex-start",
      alignSelf: "flex-start",
      position: "static",
      transform: "translate(0,0)",
      flex: "1 0 50%",
      paddingTop: theme.spacing(size === "medium" ? 2 : 1),
      paddingLeft: 0,
      [theme.breakpoints.up("md")]: {
        justifyContent: "center",
        paddingLeft: theme.spacing(2),
        ...(extended && { flex: "1 0 25%" }),
      },
    },
    "& .MuiInputBase-root": {
      flexBasis: "50%",
      [theme.breakpoints.up("md")]: {
        ...(extended && { flexBasis: "75%" }),
      },
    },
  };
});

const ColumnInputWithHelperText = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    "& .MuiFormHelperText-contained": {
      margin: theme.spacing(0.5, 0),
    },
  };
});

const InlineDatePicker = ({
  control,
  defaultValue,
  onChangeInputDate,
  shouldDisableDate,
  ...props
}) => {
  const {
    field: { name, value, onChange },
    fieldState: { error },
  } = useController({ control, defaultValue, name: props.name });

  const onChangeDateTime = (callback) => (newValue) => {
    if (onChangeInputDate) onChangeInputDate(newValue);
    callback(newValue);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
      <DesktopDatePicker
        disabled={props.disabled}
        value={value}
        onChange={onChangeDateTime(onChange)}
        shouldDisableDate={shouldDisableDate}
        renderInput={({ InputProps: { endAdornment }, ...rest }) => {
          const errorText = error?.message ? (
            <FormHelperText error={!!error}>{error.message}</FormHelperText>
          ) : null;
          return (
            <InlineFormControl disabled={props.disabled} size={props.size}>
              <InputLabel
                error={!!error}
                htmlFor={`${name}-inline__date-picker`}
              >
                {props.label}
              </InputLabel>
              <ColumnInputWithHelperText>
                <OutlinedInput
                  id={`${name}-inline__date-picker`}
                  name={name}
                  error={!!error}
                  size={props.size}
                  endAdornment={endAdornment}
                  {...rest}
                />
                {errorText}
              </ColumnInputWithHelperText>
            </InlineFormControl>
          );
        }}
      />
    </LocalizationProvider>
  );
};

InlineDatePicker.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  onChangeInputDate: PropTypes.func,
  shouldDisableDate: PropTypes.func,
  size: PropTypes.oneOf(["medium", "small"]),
};

InlineDatePicker.defaultProps = {
  size: "small",
};

export default InlineDatePicker;
