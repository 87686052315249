import { ROLES } from "constants/Role";

export const checkRole = ({ role, upn }) => {
  const upperRole = role.toUpperCase();
  /*  Guard clause  */
  if (!role || !upn) return false;

  const emailDomain = upn.substring(upn.indexOf("@") + 1);
  return ROLES[upperRole] === emailDomain;
};
