import PropTypes from "prop-types";

import { Grid, CircularProgress } from "@mui/material";

const Spinner = ({ color, children, sx }) => {
  return (
    <Grid
      container
      item
      direction="column"
      flexDirection="center"
      alignItems="center"
      sx={sx}
    >
      <CircularProgress color={color} size={64} sx={{ mb: 6 }} />
      {children}
    </Grid>
  );
};

Spinner.propTypes = {
  sx: PropTypes.object,
  color: PropTypes.oneOf(["primary", "secondary"]),
  children: PropTypes.node,
};

Spinner.defaultProps = {
  sx: {},
  color: "primary",
  children: "กำลังโหลดข้อมูล...",
};

export default Spinner;
