import { Fragment } from "react";
import PropTypes from "prop-types";

import { Grid, Typography, Radio, IconButton } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const Image = styled("img")({
  objectFit: "contain",
  width: "100%",
  flexGrow: 1,
});

const ImageAction = styled("div")(({ theme }) => {
  return {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    width: "100%",
    color: theme.palette.common.white,
    background: `linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)`,
  };
});

const LeftContent = styled("div")({
  flexGrow: 1,
});

const FormLabel = styled("label")(({ theme, disabled }) => ({
  cursor: disabled ? "default" : "pointer",
  color: disabled && "rgba(0,0,0, .3)",
}));

const ImagesList = ({
  images,
  mainImage,
  onSelectHandler,
  onRemoveHandler,
  ...props
}) => {
  const onSelectMainImage = (e) => {
    const { value } = e.target;
    onSelectHandler(value);
  };

  const onRemoveImage = (name) => (e) => {
    onRemoveHandler(name);
  };

  const controlRadioProps = (name) => ({
    checked: mainImage === name,
    id: name,
    value: name,
    name: "main-image",
    onChange: onSelectMainImage,
  });

  if (!images.length) return null;
  return (
    <Fragment>
      {images.map((image) => {
        if (image.flag_delete) return null;
        return (
          <Grid
            key={image.filename}
            container
            item
            xs={5}
            md={2.5}
            mr={2}
            mb={2}
          >
            <Grid
              container
              item
              direction="column"
              alignItems="center"
              sx={{
                position: "relative",
              }}
            >
              <ImageAction>
                <LeftContent>
                  <Radio
                    {...controlRadioProps(image.filename)}
                    disabled={props.disabled}
                  />
                  <FormLabel htmlFor={image.filename} disabled={props.disabled}>
                    รูปหลัก
                  </FormLabel>
                </LeftContent>

                <IconButton
                  onClick={onRemoveImage(image.filename)}
                  disabled={props.disabled}
                >
                  <CloseIcon />
                </IconButton>
              </ImageAction>
              <Image src={image.data} alt={`${image.filename}_img`} />
              <Typography
                component="span"
                varint="subtitle2"
                align="center"
                sx={{
                  width: "100%",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {image.filename}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </Fragment>
  );
};

ImagesList.propTypes = {
  images: PropTypes.array.isRequired,
};

ImagesList.defaultProps = {
  images: [],
};

export default ImagesList;
