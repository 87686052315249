import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import {
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  MenuItem,
} from "@mui/material";

const SelectInput = ({
  control,
  defaultValue,
  subheaderName,
  firstMenuItem,
  menuItems,
  valueProp,
  menuProp,
  onChangeSelect,
  ...props
}) => {
  const onSelectHandler = (callback) => (e) => {
    if (onChangeSelect) onChangeSelect(e.target.value);
    callback(e);
  };

  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { name, value, onChange } }) => {
        const subheaderElement = subheaderName && (
          <ListSubheader>{subheaderName}</ListSubheader>
        );
        const firstMenuElement = firstMenuItem && (
          <MenuItem value={firstMenuItem.value}>{firstMenuItem.label}</MenuItem>
        );
        const menuItemsElements =
          menuItems.length > 0 &&
          menuItems.map((item, idx) => (
            <MenuItem
              key={item.id ? item.id : idx}
              value={valueProp ? item[valueProp] : item}
            >
              {menuProp ? item[menuProp] : item}
            </MenuItem>
          ));

        return (
          <FormControl
            variant={props.variant}
            disabled={props.disabled}
            fullWidth
          >
            <InputLabel id={`${name}__select-input`} variant={props.variant}>
              {props.label}
            </InputLabel>
            <Select
              labelId={`${name}__select-input`}
              name={name}
              value={value}
              onChange={onSelectHandler(onChange)}
              size={props.size}
              variant={props.variant}
            >
              {subheaderElement}
              {firstMenuElement}
              {menuItemsElements}
            </Select>
          </FormControl>
        );
      }}
    />
  );
};

SelectInput.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(["outlined", "filled", "standard"]),
  size: PropTypes.oneOf(["medium", "small"]),
  subheaderName: PropTypes.string,
  firstMenuItem: PropTypes.exact({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  menuItems: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  onChangeSelect: PropTypes.func,
};

SelectInput.defaultProps = {
  disabled: false,
  menuItems: [],
  size: "small",
  variant: "standard",
};

export default SelectInput;
