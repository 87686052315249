import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import {
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const InlineFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== "size" && prop !== "inputLength",
})(({ theme, size, inputLength }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiInputLabel-formControl": {
      display: "flex",
      justifyContent: "flex-start",
      alignSelf: "flex-start",
      position: "static",
      transform: "translate(0,0)",
      flex: `1 0 ${inputLength.label.xs}`,
      paddingTop: theme.spacing(size === "medium" ? 2 : 1),
      paddingLeft: 0,
      [theme.breakpoints.up("md")]: {
        justifyContent: "center",
        paddingLeft: theme.spacing(2),
        flex: `1 0 ${inputLength.label.md}`,
      },
      [theme.breakpoints.up("lg")]: {
        flex: `1 0 ${inputLength.label.lg}`,
      },
    },
    "& .MuiInputBase-root": {
      flexBasis: inputLength.input.xs,
      [theme.breakpoints.up("md")]: {
        flexBasis: inputLength.label.md,
      },
      [theme.breakpoints.up("lg")]: {
        flexBasis: inputLength.label.lg,
      },
    },
  };
});

const ColumnInputWithHelperText = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    "& .MuiFormHelperText-contained": {
      margin: theme.spacing(0.5, 0),
    },
  };
});

const InlineSelectInput = ({
  control,
  defaultValue,
  subheaderName,
  firstMenuItem,
  menuItems,
  valueProp,
  menuProp,
  inputLength,
  onChangeSelect,
  ...props
}) => {
  const onSelectHandler = (callback) => (e) => {
    if (onChangeSelect) onChangeSelect(e.target.value);
    callback(e);
  };

  return (
    <Controller
      name={props.name}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => {
        const subheaderElement = subheaderName && (
          <ListSubheader>{subheaderName}</ListSubheader>
        );
        const firstMenuElement = firstMenuItem && (
          <MenuItem value={firstMenuItem.value}>{firstMenuItem.label}</MenuItem>
        );
        const menuItemsElements =
          menuItems.length > 0 &&
          menuItems.map((item, idx) => (
            <MenuItem
              key={item.id ? item.id : idx}
              value={valueProp ? item[valueProp] : item}
            >
              {menuProp ? item[menuProp] : item}
            </MenuItem>
          ));

        const errorText = error?.message ? (
          <FormHelperText error={!!error}>{error.message}</FormHelperText>
        ) : null;

        return (
          <InlineFormControl
            variant="outlined"
            disabled={props.disabled}
            error={!!error}
            size={props.size}
            inputLength={inputLength}
          >
            <InputLabel id={`${name}-inline__select-input`} error={!!error}>
              {props.label}
            </InputLabel>
            <ColumnInputWithHelperText>
              <Select
                labelId={`${name}-inline__select-input`}
                name={name}
                value={value}
                onChange={onSelectHandler(onChange)}
                size={props.size}
              >
                {subheaderElement}
                {firstMenuElement}
                {menuItemsElements}
              </Select>
              {errorText}
            </ColumnInputWithHelperText>
          </InlineFormControl>
        );
      }}
    />
  );
};

InlineSelectInput.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(["medium", "small"]),
  subheaderName: PropTypes.string,
  firstMenuItem: PropTypes.exact({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  menuItems: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
  onChangeSelect: PropTypes.func,
  inputLength: PropTypes.exact({
    label: PropTypes.shape({
      xs: PropTypes.string,
      sm: PropTypes.string,
      md: PropTypes.string,
      lg: PropTypes.string,
      xl: PropTypes.string,
    }),
    input: PropTypes.shape({
      xs: PropTypes.string,
      sm: PropTypes.string,
      md: PropTypes.string,
      lg: PropTypes.string,
      xl: PropTypes.string,
    }),
  }),
};

InlineSelectInput.defaultProps = {
  disabled: false,
  menuItems: [],
  size: "small",
  inputLength: {
    label: {
      xs: "50%",
      sm: "50%",
      md: "50%",
      lg: "50%",
      xl: "50%",
    },
    input: {
      xs: "50%",
      sm: "50%",
      md: "50%",
      lg: "50%",
      xl: "50%",
    },
  },
};

export default InlineSelectInput;
