import { addDays, isBefore, isAfter } from "date-fns";

import { MAX_PRE_RESERVED_DAYS } from "constants/DateTime";

export const yearOptions = (countdownYear) => {
  const yearTH = new Intl.DateTimeFormat("th-TH", { year: "numeric" }).format(
    new Date()
  );
  const [, presentYear] = yearTH.split(" ");
  const totalYears = [...Array(countdownYear + 1).keys()].map(
    (year) => year + (presentYear - countdownYear)
  );
  return totalYears;
};

export const disableCalendarDate = (comparedDate) => (date) => {
  const lastDateToChangeReserved = addDays(comparedDate, MAX_PRE_RESERVED_DAYS);
  return (
    isBefore(date, comparedDate) || isAfter(date, lastDateToChangeReserved)
  );
};
