import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent,
  IconButton,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  "& .MuiIconButton-root": {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

const Modal = ({
  open,
  onClose,
  fullScreen,
  dialogTitle,
  titleWithClose,
  dividers,
  ...props
}) => {
  const header = (dialogTitle || titleWithClose) && (
    <DialogTitle id="dialog-title">
      {dialogTitle}
      {titleWithClose && (
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </DialogTitle>
  );

  return (
    <Dialog
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      onClose={onClose}
      open={open}
      fullScreen={fullScreen}
    >
      {header}
      <DialogContent
        id="dialog-description"
        dividers={dividers}
        sx={{ ...props.sx }}
      >
        {props.children}
      </DialogContent>
    </Dialog>
  );
};

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  fullScreen: PropTypes.bool,
  dialogTitle: PropTypes.node,
  titleWithClose: PropTypes.bool,
  dividers: PropTypes.bool,
};

Modal.defaultProps = {
  titleWithClose: false,
  dividers: false,
};

export default Modal;
