import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useLocation } from "react-router-dom";

import { Backdrop } from "@mui/material";

import { loginRequest } from "config/msalAuthentication";

const LoginPage = () => {
  const { instance } = useMsal();
  const location = useLocation();

  useEffect(() => {
    instance.loginRedirect({
      ...loginRequest,
      redirectUri: `${
        process.env.REACT_APP_BASE_URI || "http://localhost:3000"
      }${location?.state?.from || ""}`,
    });
  }, [instance, location]);

  return <Backdrop open={true} />;
};

export default LoginPage;
