// import Compose from "contexts/Compose";
import { BrowserRouter as Router } from "react-router-dom";
import MsalAuthProvider from "contexts/msal-auth/MsalAuthProvider";
import AuthProvider from "contexts/auth/AuthProvider";

import Pages from "router";

function App({ pca }) {
  return (
    // <Compose components={[AuthProvider, Router]}>
    <Router>
      <MsalAuthProvider pca={pca}>
        <AuthProvider>
          <Pages />
        </AuthProvider>
      </MsalAuthProvider>
    </Router>
    // </Compose>
  );
}

export default App;
