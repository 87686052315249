// import { Fragment } from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  // Container,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Agriculture as AgricultureIcon,
  Article as ArticleIcon,
  Layers as LayersIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { checkRole } from "helpers/Role";

const drawerWidth = 220;

const Sidebar = ({ children }) => {
  const { accounts } = useMsal();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const upn =
    accounts.length > 0 && accounts[0]?.idTokenClaims?.upn
      ? accounts[0].idTokenClaims.upn
      : null;

  const drawerListItems = [
    {
      menuName: "เครื่องจักรทั้งหมด",
      icon: <AgricultureIcon sx={{ fontSize: 32 }} />,
      page: "/machines",
    },
    {
      menuName: "สถานะการเช่า",
      icon: <ArticleIcon sx={{ fontSize: 32 }} />,
      page: "/bookings",
    },
    (checkRole({ role: "skc", upn }) || checkRole({ role: "kin", upn })) && {
      menuName: "จัดการข้อมูลหลัก",
      icon: <LayersIcon sx={{ fontSize: 32 }} />,
      page: "/master-data",
    },
  ];

  const navigatePage = (page) => () => {
    navigate(page);
  };

  const openedMixin = ({ palette, transitions, spacing }) => ({
    width: drawerWidth,
    overflowX: "hidden",
    marginTop: spacing(2),
    transition: transitions.create("width", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.enteringScreen,
    }),
    "& .MuiListItem-root": {
      position: "relative",
      "&.Mui-selected::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: palette.primary.main,
        width: "5px",
        height: "100%",
        transform: "scaleY(1)",
      },
    },
  });

  const closedMixin = ({ palette, transitions, spacing }) => ({
    width: `calc(${spacing(8)} + 1px)`,
    overflowX: "hidden",
    marginTop: spacing(3),
    transition: transitions.create("width", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
    "& .MuiListItem-root": {
      position: "relative",
      "&.Mui-selected::before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: palette.primary.main,
        width: "5px",
        height: "100%",
        transform: "scaleY(1)",
      },
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme }) => ({
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
    [theme.breakpoints.up("sm")]: {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    },
  }));

  const OffsetTop = styled("div")(({ theme }) => theme.mixins.toolbar);

  const MainContent = styled("div")({
    display: "flex",
  });

  return (
    <MainContent>
      <Drawer variant="permanent">
        <OffsetTop />

        <List>
          {drawerListItems.map(({ menuName, icon, page }) => {
            if (!menuName || !page) return null;
            return (
              <ListItem
                selected={pathname.indexOf(page) !== -1}
                key={menuName}
                button
                onClick={navigatePage(page)}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={menuName} />
              </ListItem>
            );
          })}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{
          pt: { xs: 4, sm: 3 },
          flexGrow: 1,
        }}
      >
        {children}
      </Box>
      {/* <Container
        maxWidth="md"
        component="main"
        sx={{ pt: { xs: 4, sm: 3 }, flexGrow: 1 }}
      >
        {children}
      </Container> */}
    </MainContent>
  );
};

export default Sidebar;
