import PropTypes from "prop-types";

import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const MachineCard = styled(Grid)(
  ({ theme, props: { active, select, disabled }, props }) => {
    const setBorderStyle = () => {
      if (disabled) return select && "3px solid rgba(0,0,0,.26)";
      if (!disabled)
        return select && active && `3px solid ${theme.palette.primary.main}`;
      return "3px solid transparent";
    };
    return {
      cursor: !disabled && "pointer",
      transition: "border 0.3s ease-in-out",
      border: setBorderStyle(),
      borderRadius: ".5rem",
      // padding: theme.spacing(0, 2),
      marginRight: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        marginBottom: theme.spacing(2),
      },
      "&.MuiGrid-item": {
        minWidth: theme.spacing(20),
        padding: theme.spacing(0.5, 0.5),
      },
    };
  }
);

const MachineDetail = styled(Grid)(({ theme, props: { active, disabled } }) => {
  const setFilterStyle = () => {
    if (disabled)
      return active ? "grayscale(100%)" : "blur(1px) grayscale(100%)";
    if (!disabled) return !active && "blur(1px) grayscale(100%)";
    return "";
  };
  return {
    margin: 0,
    marginTop: theme.spacing(0.5),
    "& img": {
      transition: `filter 0.3s ease-in-out`,
      filter: setFilterStyle(),
      width: "100%",
      objectFit: "contain",
      flexGrow: 1,
      marginBottom: theme.spacing(1),
    },
    "& .MuiTypography-root": {
      transition: `color 0.3s ease-in-out`,
    },
  };
});

const MachineTypeList = ({
  imgUrl,
  name,
  active,
  select,
  onClickSelectMachine,
  disabled,
}) => {
  const onClick = (typeName) => () => {
    if (disabled) return;
    onClickSelectMachine(typeName);
  };

  const setFontColorStyle = () => {
    if (disabled) return "rgba(0,0,0,.3)";
    if (!disabled) return select && active && "primary.main";
    return "common.black";
  };

  return (
    <MachineCard
      container
      item
      xs={6}
      sm={5}
      md={2}
      props={{ active, select, disabled }}
    >
      <MachineDetail
        component="figure"
        container
        item
        direction="column"
        justifyContent="center"
        alignItems="center"
        props={{ active, disabled }}
        onClick={onClick(name)}
      >
        <img src={imgUrl} alt={`${name}-type__img`} />
        <Typography
          component="figcaption"
          variant="caption"
          mt="auto"
          sx={{ color: setFontColorStyle() }}
        >
          {name}
        </Typography>
      </MachineDetail>
    </MachineCard>
  );
};

MachineTypeList.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  select: PropTypes.bool,
  disabled: PropTypes.bool,
};

MachineTypeList.defaultProps = {
  active: true,
  select: false,
  disabled: false,
};

export default MachineTypeList;
