import PropTypes from "prop-types";
import { Grid, Typography, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close as CloseIcon } from "@mui/icons-material";

const ImageDetail = styled("div", {
  shouldForwardProp: (prop) => prop !== "sx" && prop !== "isMain",
})(({ theme }) => {
  return {
    position: "absolute",
    top: 0,
    left: 0,
    display: "flex",
    flex: "1 0 100%",
    width: "100%",
    color: theme.palette.common.white,
    textShadow: `2px 2px ${theme.palette.common.black}`,
    background: `linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)`,
  };
});

const Image = styled("img")({
  objectFit: "contain",
  width: "100%",
  flexGrow: 1,
});

const PreviewImage = ({
  imgSrc,
  imgName,
  imgPosition,
  isMain,
  field,
  onRemoveHandler,
  error,
  ...props
}) => {
  const onRemoveImageHandler = (field, imgName, imgSrc) => () => {
    onRemoveHandler(field, { filename: imgName, data: imgSrc });
  };

  const lastIndexSubstr = imgName.lastIndexOf(".");
  const imgNameWithoutFileType = imgName.substring(0, lastIndexSubstr);

  return (
    <Grid
      container
      item
      xs={12}
      sm={6}
      md={4}
      mb={2}
      sx={{ borderRadius: (theme) => theme.spacing(1) }}
    >
      <Grid
        container
        item
        direction="column"
        alignItems="center"
        sx={{
          position: "relative",
          border: (theme) =>
            !!error
              ? `2px solid ${theme.palette.error.main}`
              : `1px solid ${theme.palette.grey[500]}`,
          mx: 1,
        }}
      >
        <ImageDetail isMain={isMain}>
          <Typography variant="body2" sx={{ my: "auto", ml: 1, flexGrow: 1 }}>
            {isMain ? "รูปหลัก - " : null}
            {imgPosition}
          </Typography>
          <IconButton
            onClick={onRemoveImageHandler(field, imgName, imgSrc)}
            disabled={props.disabled}
          >
            <CloseIcon sx={{ "&:hover": { color: "error.main" } }} />
          </IconButton>
        </ImageDetail>
        <Image src={imgSrc} alt={`${imgNameWithoutFileType}-${imgPosition}`} />
        <Typography
          component="span"
          variant="h6"
          textAlign="center"
          sx={{
            width: "100%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {imgName}
        </Typography>
      </Grid>
    </Grid>
  );
};

ImageDetail.propTypes = {
  imgSrc: PropTypes.string,
  isMain: PropTypes.bool,
};

ImageDetail.defaultProps = {
  isMain: false,
};

export default PreviewImage;
