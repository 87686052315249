import { MsalProvider } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { CustomNavigationClient } from "config/NavigationClient";

const MsalAuthProvider = ({ pca, children }) => {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return <MsalProvider instance={pca}>{children}</MsalProvider>;
};

export default MsalAuthProvider;
