export const AD_STATUS = {
  WAITING: { nameEN: "waiting" },
  DELIVERY: { nameEN: "delivery", nameTH: "ส่งมอบเครื่องจักร" },
  ACTIVE: { nameEN: "active" },
  COMPLETED: { nameEN: "completed" },
  RETURN: { nameEN: "return", nameTH: "รับคืนเครื่องจักร" },
};

export const CLIENT_STATUS = {
  DRAFT: { nameEN: "draft" },
  BOOK: { nameEN: "book" },
  PAID1: { nameEN: "paid1" },
  ACTIVE: { nameEN: "active" },
  PAID2: { nameEN: "paid2" },
  CANCEL: { nameEN: "cancel" },
  COMPLETED: { nameEN: "completed" },
};
