import { Fragment } from "react";
import PropsTypes from "prop-types";

import { Fab, FormHelperText } from "@mui/material";
import { AddPhotoAlternateRounded as AddPhotoIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import { Controller } from "react-hook-form";
import { fileToDataUri } from "helpers/Utilies";

const FileInput = styled("input")({
  display: "none",
});

const ButtonFileInput = ({
  control,
  defaultValue,
  onSelectImages,
  multiple,
  ...props
}) => {
  const onSelectFiles = (value, onChange) => async (e) => {
    const { files } = e.target;
    const imagesPromises = [];
    for (const file of Object.values(files)) {
      imagesPromises.push(fileToDataUri(file));
    }
    const images = await Promise.all(imagesPromises);
    // onChange([...value, ...images]);
    onSelectImages(images, onChange);
  };

  return (
    <Controller
      control={control}
      name={props.name}
      defaultValue={defaultValue}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => {
        const errorText = error?.message ? (
          <FormHelperText error={!!error}>{error.message}</FormHelperText>
        ) : null;
        return (
          <Fragment>
            <label htmlFor={`${name}__file-input`}>
              <FileInput
                id={`${name}__file-input`}
                name={name}
                multiple={multiple}
                type="file"
                accept="image/*"
                onChange={onSelectFiles(value, onChange)}
                disabled={props.disabled}
              />
              <Fab
                disabled={props.disabled}
                component="span"
                color="secondary"
                size="small"
                variant="extended"
                sx={{ px: 2, mr: 2 }}
              >
                เพิ่มรูป
                <AddPhotoIcon sx={{ ml: 1 }} />
              </Fab>
            </label>
            {errorText}
          </Fragment>
        );
      }}
    />
  );
};

ButtonFileInput.propTypes = {
  multiple: PropsTypes.bool,
};

export default ButtonFileInput;
