import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  swal2: {
    "&.swal2-container": {
      zIndex: theme.zIndex.drawer + 1,
      "& .swal2-confirm": {
        fontFamily: "Prompt",
        backgroundColor: theme.palette.primary.main,
        "&:focus": {
          boxShadow: `0 0 0 3px ${theme.palette.primary.dark}`,
        },
      },
    },
  },
}));

const baseUri =
  process.env.REACT_APP_API_BASE_URL ||
  "https://k-rent-backend-ad.azurewebsites.net";

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const classes = useStyles();

  const sendRequest = useCallback(
    async (requestConfig, applyData) => {
      setIsLoading(true);
      setError(null);

      const {
        url,
        endpoint = "",
        method = "GET",
        headers,
        body,
        signal = null,
      } = requestConfig;
      const apiUrl = `${url ? url : baseUri}${endpoint}`;
      const headersOption = {
        ...headers,
        ...((method === "POST" || method === "PUT") && {
          "Content-Type": "application/json",
        }),
      };
      try {
        const response = await fetch(apiUrl, {
          method,
          headers: headersOption,
          body: body ? JSON.stringify(body) : null,
          signal: signal ? signal : null,
        });
        if (!response.ok) {
          // console.log("response: ", response);
          if (response.status === 401) {
            console.log("[401]");
            const resExpiredSession = await new Swal({
              titleText:
                "ระยะเวลาการเข้าใช้งานของท่านหมดแล้ว กรุณาเข้าสู่ระบบใหม่อีกครั้ง",
              icon: "warning",
              confirmButtonText: "เข้าสู่ระบบใหม่อีกครั้ง",
              width: "50%",
              showCloseButton: true,
              focusConfirm: true,
              customClass: { container: classes.swal2 },
            });
            if (resExpiredSession.isConfirmed) {
              localStorage.clear();
              sessionStorage.clear();
              return window.location.reload();
            }
          }
          if (response.status === 403) {
            console.log("[403]");
            return navigate("/error/access-forbidden");
          }
          if (response.status === 500) {
            console.log("[500]");
            return navigate("/error/internal-server-error");
          }
          throw new Error("Request failed!");
        } else {
          const data = await response.json();
          applyData(data);
        }
      } catch (err) {
        setError(err.message || "Something went wrong!");
        // console.dir(err);
      }
      setIsLoading(false);
    },
    [classes, navigate]
  );

  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default useHttp;
