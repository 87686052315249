import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const InlineFormControl = styled(FormControl)(({ theme, size, extended }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiInputLabel-formControl": {
      display: "flex",
      justifyContent: "flex-start",
      alignSelf: "flex-start",
      position: "static",
      transform: "translate(0,0)",
      flex: "1 0 50%",
      paddingTop: theme.spacing(size === "medium" ? 2 : 1),
      paddingLeft: 0,
      [theme.breakpoints.up("md")]: {
        justifyContent: "center",
        paddingLeft: theme.spacing(2),
        ...(extended && { flex: "1 0 25%" }),
      },
    },
    "& .MuiInputBase-root": {
      flexBasis: "50%",
      [theme.breakpoints.up("md")]: {
        ...(extended && { flexBasis: "75%" }),
      },
    },
  };
});

const ColumnInputWithHelperText = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "column",
    flexBasis: "100%",
    "& .MuiFormHelperText-contained": {
      margin: theme.spacing(0.5, 0),
    },
  };
});

const InlineInput = ({
  control,
  defaultValue,
  multiline,
  rows,
  extended,
  helper,
  ...props
}) => {
  const onWheelNumberInputPreventChange = (e) => {
    if (e.target.type === "number") {
      // Prevent the input value change
      e.target.blur();

      // Prevent the page/container scrolling
      e.stopPropagation();

      // Refocus immediately
      setTimeout(() => {
        e.target.focus();
      }, 0);
    } else return;
  };

  return (
    <Controller
      name={props.name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => {
        const helperText = helper ? (
          <FormHelperText>{helper}</FormHelperText>
        ) : null;

        const errorText = error?.message ? (
          <FormHelperText error={!!error}>{error.message}</FormHelperText>
        ) : null;
        return (
          <InlineFormControl
            disabled={props.disabled}
            size={props.size}
            extended={extended ? 1 : 0}
          >
            <InputLabel error={!!error} htmlFor={`${name}-inline__input`}>
              {props.label}
            </InputLabel>
            <ColumnInputWithHelperText>
              <OutlinedInput
                id={`${name}-inline__input`}
                name={name}
                type={props.type}
                multiline={multiline}
                rows={rows}
                value={value}
                onChange={onChange}
                onWheel={onWheelNumberInputPreventChange}
                error={!!error}
                size={props.size}
              />
              {helperText}
              {errorText}
            </ColumnInputWithHelperText>
          </InlineFormControl>
        );
      }}
    />
  );
};

InlineInput.propTypes = {
  control: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  extended: PropTypes.bool,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  helper: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  size: PropTypes.oneOf(["medium", "small"]),
};

InlineInput.defaultProps = {
  type: "text",
  disabled: false,
  multiline: false,
  extended: false,
  size: "small",
};

export default InlineInput;
