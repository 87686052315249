export const typography = {
  fontFamily: "Prompt",
  secondary: {
    color: "rgba(22,21,21,.7)",
  },
};

export const palette = {
  background: {
    default: "#fff",
  },
  primary: {
    dark: "#0a8137",
    main: "#0DB14B",
    contrastText: "#fff",
    light: "#DBF3E4",
  },
  secondary: {
    dark: "#580047",
    main: "#7F0166",
    contrastText: "#fff",
    light: "#D7B0D0",
  },
  common: {
    main: "#978F8F",
    contrastText: "#000",
    darker: "#423d3d",
    lighter: "#a9a2a2",
  },
};

// body {
// ::-webkit-scrollbar {
//   width: 7px;
// }
// ::-webkit-scrollbar-track {
//   background: #D7B0D0;
// }
// ::-webkit-scrollbar-thumb {
//   background: #7F0166;
//   border-radius: 5px;
// }
// ::-webkit-scrollbar-thumb:hover {
//   background: #580047;
// }
// }

export const components = {
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'Prompt';
        font-style: normal;
        font-display: swap;
        font-weight: 400;
        src:  url('https://krent-font.azureedge.net/fonts/Prompt/truetype/Prompt-Regular.ttf') format('truetype'),
              url('https://krent-font.azureedge.net/fonts/Prompt/woff/Prompt-Regular.woff') format('woff'),
              url('https://krent-font.azureedge.net/fonts/Prompt/woff2/Prompt-Regular.woff2') format('woff2');
      }
    `,
  },
};
