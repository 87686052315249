import { useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
// import { useNavigate } from "react-router-dom";
import {
  InteractionStatus,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";

import AuthContext from "./auth-context";

const AuthProvider = (props) => {
  const { instance, accounts, inProgress } = useMsal();
  const [idToken, setIdToken] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      !loading &&
      inProgress === InteractionStatus.None &&
      accounts.length > 0
    ) {
      if (idToken && accessToken) {
        return;
      }

      const tokenRequest = {
        scopes: ["User.Read"],
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(tokenRequest)
        .then((response) => {
          setIdToken(response.idToken);
          setAccessToken(response.accessToken);
          setLoading(false);
        })
        .catch(async (error) => {
          // acquireTokenSilent can fail for a number of reasons, fallback to interaction
          if (error instanceof InteractionRequiredAuthError) {
            await instance.acquireTokenRedirect({
              ...tokenRequest,
              loginHint: accounts[0].username,
            });
          }
        });
    }
  }, [inProgress, accounts, instance, loading, idToken, accessToken]);

  return (
    <AuthContext.Provider value={{ idToken, accessToken }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
