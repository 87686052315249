import {
  Fragment,
  useEffect,
  useReducer,
  useRef,
  useState,
  useContext,
} from "react";
import { useMsal } from "@azure/msal-react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import {
  Alert as MuiAlert,
  Backdrop,
  Button,
  Fab,
  CircularProgress,
  Chip,
  Grid,
  Typography,
} from "@mui/material";
import {
  Edit as EditIcon,
  FactCheck as FactCheckIcon,
  DeleteForever as DeleteForeverIcon,
  Close as CloseIcon,
  Check as CheckIcon,
  AddCircleOutline as AddCircleOutlineIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import exImgLeft from "assets/ex_01_left.jpg";
import exImgRight from "assets/ex_02_right.jpg";
import exImgFront from "assets/ex_03_front.jpg";
import exImgBack from "assets/ex_04_back.jpg";
import exImgDial from "assets/ex_05_dial.jpg";

import AuthContext from "contexts/auth/auth-context";
import { Alert, PreviewImage, MachineTypeList, Modal } from "components";
import {
  CardFileInput,
  FileInput,
  InlineInput,
  InlineRadioInput,
  InlineSelectInput,
} from "components/HookForms";

import { useHttp } from "hooks";
import { STATUS } from "constants/Machine";
import { COUNTDOWN_YEAR } from "constants/DateTime";
import { yearOptions } from "helpers/DateTime";
import { checkRole } from "helpers/Role";
import { deconstructFileUrl, isUrl } from "helpers/Utilies";

const defaultMachineOptions = {
  typeSelected: "",
  modelOptions: [],
};

const machineOptionsReducer = (state, action) => {
  switch (action.type) {
    case "SELECT_TYPE":
      return { typeSelected: action.name, modelOptions: [] };
    case "FILL_OPTIONS":
      return { ...state, modelOptions: action.models };
    default:
      return state;
  }
};

const schema = yup.object().shape({
  preparedStatus: yup.string(),
  model: yup.string().required("กรุณาเลือก รุ่นสินค้า"),
  year: yup.number().typeError("กรุณาเลือก ปีสินค้า"),
  hour: yup
    .number()
    .typeError("กรุณากรอก ชั่วโมงรถปัจจุบัน")
    .integer("จำนวนเต็ม เท่านั้น")
    .positive("จำนวนชั่วโมงต้องมากกว่า 0 ขึ้นไป"),
  VIN: yup.string().trim().required("กรุณากรอก หมายเลขรถ"),
  engineNo: yup.string().trim().required("กรุณากรอก หมายเลขเครื่องยนต์"),
  licenseNo: yup.string().trim().required("กรุณากรอก หมายเลขทะเบียน"),
  act: yup.bool().typeError("กรุณาเลือก พ.ร.บ"),
  gps: yup.bool().typeError("กรุณาเลือก GPS"),
  qualityDoc: yup.mixed().required("กรุณาอัพโหลด เอกสารการประเมินคุณภาพ"),
  imageLeft: yup.object().typeError("กรุณาอัพโหลด รูปภาพด้านข้างซ้าย"),
  imageRight: yup.object().typeError("กรุณาอัพโหลด รูปภาพด้านข้างขวา"),
  imageFront: yup.object().typeError("กรุณาอัพโหลด รูปภาพด้านหน้า"),
  imageBack: yup.object().typeError("กรุณาอัพโหลด รูปภาพด้านหลัง"),
  imageDial: yup
    .object()
    .typeError("กรุณาอัพโหลด รูปภาพหน้าปัดชั่วโมงการทำงาน"),
  imageOther: yup
    .array()
    .nullable()
    .max(5, "สามารถอัพโหลดรูปอื่นๆได้สูงสุด ไม่เกิน 5 รูป"),
});

const useStyles = makeStyles((theme) => ({
  swal2: {
    "&.swal2-container": {
      zIndex: theme.zIndex.drawer + 1,
      "& .swal2-confirm": {
        fontFamily: "Prompt",
        backgroundColor: theme.palette.primary.main,
        "&:focus": {
          boxShadow: `0 0 0 3px ${theme.palette.primary.dark}`,
        },
      },
      "& .swal2-cancel": {
        fontFamily: "Prompt",
        backgroundColor: theme.palette.grey[500],
      },
    },
  },
  swal2__delete: {
    "&.swal2-container": {
      zIndex: theme.zIndex.drawer + 1,
      "& .swal2-confirm": {
        fontFamily: "Prompt",
        backgroundColor: theme.palette.error.main,
        "&:focus": {
          boxShadow: `0 0 0 3px ${theme.palette.error.dark}`,
        },
      },
      "& .swal2-cancel": {
        fontFamily: "Prompt",
        backgroundColor: theme.palette.grey[500],
      },
    },
  },
}));

const Form = styled("form")({
  width: "100%",
});

const IFrameDoc = styled("iframe")({
  height: "90vh",
  width: "100%",
});

const ImageDoc = styled("img")({
  objectFit: "contain",
  width: "80%",
});

const MachineFormPage = () => {
  const [editMode, setEditMode] = useState(false);
  const [machineStatus, setMachineStatus] = useState("DRAFT");
  const [rejectedMessage, setRejectedMessage] = useState("");
  const [delay, setDelay] = useState(null);
  const [deletedImages, setDeletedImages] = useState([]);
  const [machineTypes, setMachineTypes] = useState([]);
  const [machineOptions, dispatchMachineOptionsAction] = useReducer(
    machineOptionsReducer,
    defaultMachineOptions
  );
  const [modalPreviewFile, setModalPreviewFile] = useState(false);
  const [qualityDoc, setQualityDoc] = useState([]);
  const [exampleImage, setExampleImage] = useState({
    isShow: false,
    imgSrc: "",
    imgPosition: "",
  });
  const [statusSuccess, setStatusSuccess] = useState({
    message: "",
    isShow: false,
    hideDuration: 4000,
  });
  const [statusError, setStatusError] = useState({
    message: "",
    isShow: false,
    hideDuration: 4000,
  });
  const { idToken, accessToken } = useContext(AuthContext);
  const fileInputRef = useRef(null);

  const { accounts } = useMsal();
  const { upn } = accounts[0].idTokenClaims;

  const { VIN } = useParams();
  const navigate = useNavigate();

  const classes = useStyles();

  const {
    control,
    setValue,
    clearErrors,
    handleSubmit,
    formState: {
      errors: { imageOther: imageOtherError },
    },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [
    imageLeftValue,
    imageRightValue,
    imageFrontValue,
    imageBackValue,
    imageDialValue,
    imageOtherValue,
  ] = useWatch({
    control,
    name: [
      "imageLeft",
      "imageRight",
      "imageFront",
      "imageBack",
      "imageDial",
      "imageOther",
    ],
  });

  const { sendRequest: fetchMachineTypes } = useHttp();
  const { sendRequest: fetchMachineData } = useHttp();
  const { sendRequest: sendPutStatus } = useHttp();
  const { isLoading: isSending, sendRequest: sendUpdateRequest } = useHttp();

  useEffect(() => {
    const abortCont = new AbortController();
    const { signal } = abortCont;

    const setResponseMachineTypes = ({ machines }) => {
      const typesWithProps = machines.map((type) => ({
        ...type,
        select: false,
        active: true,
      }));
      setMachineTypes(typesWithProps);
    };

    const setResponseMachineData = (machineData) => {
      setMachineStatus(machineData.status);
      setRejectedMessage(() => {
        if (machineData.reasonRejected) return machineData.reasonRejected;
        return "";
      });
      fetchMachineTypes(
        {
          endpoint: "/machine/type",
          headers: { id_token: idToken, access_token: accessToken },
        },
        ({ machines }) => {
          const machineTypesWithSelectedItem = machines.map((type) => ({
            ...type,
            select: type.nameTH === machineData.nameTH,
            active: type.nameTH === machineData.nameTH,
          }));
          setMachineTypes(machineTypesWithSelectedItem);
          dispatchMachineOptionsAction({
            type: "SELECT_TYPE",
            name: machineData.nameTH,
          });
          setValue("model", machineData.model);
        }
      );
      setValue(
        "preparedStatus",
        machineData.status === STATUS.DISABLE.nameEN
          ? machineData.status
          : "enable"
      );
      setValue("year", machineData.year);
      setValue("hour", machineData.hour);
      setValue("VIN", machineData.VIN);
      setValue("engineNo", machineData.engineNo);
      setValue("licenseNo", machineData.licenseNo);
      setValue("act", machineData.act);
      setValue("gps", machineData.gps.having);
      setQualityDoc(() => {
        if (!machineData.qualityDoc.length) return [];
        const qualityDocPath = new URL(machineData.qualityDoc[0]).pathname;
        const qualityDocPathname = decodeURIComponent(
          qualityDocPath.substring(qualityDocPath.lastIndexOf("/") + 1)
        );
        const docFilename = qualityDocPathname.substring(
          qualityDocPathname.indexOf("/") + 1
        );
        setValue("qualityDoc.filename", docFilename);
        setValue("qualityDoc.data", machineData.qualityDoc[0]);
        return [
          {
            filename: docFilename,
            data: machineData.qualityDoc[0],
            flag_delete: false,
          },
        ];
      });
      setValue("imageLeft", deconstructFileUrl(machineData.imageLeft));
      setValue("imageRight", deconstructFileUrl(machineData.imageRight));
      setValue("imageFront", deconstructFileUrl(machineData.imageFront));
      setValue("imageBack", deconstructFileUrl(machineData.imageBack));
      setValue("imageDial", deconstructFileUrl(machineData.imageDial));
      setValue(
        "imageOther",
        machineData.imageOther.map((imageUrl) => deconstructFileUrl(imageUrl))
      );
    };

    const fetchHttp = (signal) => {
      if (VIN)
        fetchMachineData(
          {
            endpoint: `/machine/id/${VIN}`,
            headers: { id_token: idToken, access_token: accessToken },
            signal,
          },
          setResponseMachineData
        );
      else
        fetchMachineTypes(
          {
            endpoint: "/machine/type",
            headers: { id_token: idToken, access_token: accessToken },
            signal,
          },
          setResponseMachineTypes
        );
    };

    fetchHttp(signal);

    return () => abortCont.abort();
  }, [
    fetchMachineTypes,
    fetchMachineData,
    idToken,
    accessToken,
    VIN,
    setValue,
  ]);

  useEffect(() => {
    let mounted = true;
    if (mounted && machineTypes.length > 0 && machineOptions.typeSelected) {
      const selectedMachineType = machineTypes.find(
        (type) => type.nameTH === machineOptions.typeSelected
      );
      dispatchMachineOptionsAction({
        type: "FILL_OPTIONS",
        models: selectedMachineType.model,
      });
    }

    return () => {
      mounted = false;
    };
  }, [machineTypes, machineOptions.typeSelected]);

  const onOpenEditMode = () => {
    setEditMode(true);
  };

  const confirmedSendingApprove = async () => {
    const response = await new Swal({
      titleText: "ยืนยันส่งข้อมูลเพื่อตรวจสอบหรือไม่",
      text: "การยืนยันส่งข้อมูลเพื่อรอการตรวจสอบข้อมูลเครื่องจักร จะไม่สามารถกลับมาแก้ไขข้อมูลได้อีกจนกว่าจะผ่านการเช่าในครั้งถัดไป",
      icon: "question",
      cancelButtonText: "กลับหน้าเดิม",
      confirmButtonText: "ยืนยัน",
      width: "50%",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      reverseButtons: true,
      customClass: { container: classes.swal2 },
    });
    if (response.isConfirmed)
      handleSubmit(submitMachineData(STATUS.WAITING.nameEN))();
  };

  const confirmedDeleteMachine = async () => {
    const response = await new Swal({
      titleText: "ยืนยันลบเครื่องจักรออกจากระบบหรือไม่",
      icon: "question",
      cancelButtonText: "กลับหน้าเดิม",
      confirmButtonText: "ยืนยัน",
      width: "50%",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      reverseButtons: true,
      customClass: { container: classes.swal2__delete },
    });
    if (response.isConfirmed) {
      sendUpdateRequest(
        {
          endpoint: `/machine/delete/${VIN}`,
          headers: { id_token: idToken, access_token: accessToken },
          method: "PUT",
        },
        (deletedResponse) => {
          console.log("deleted machine: ", deletedResponse);
          setStatusSuccess({
            message: `ลบเครื่องจักร หมายเลข ${deletedResponse.VIN} เรียบร้อยแล้ว`,
            isShow: true,
            hideDuration: 2000,
          });
          setTimeout(() => {
            navigate("/machines");
          }, 2000);
        }
      );
    }
  };

  const onSelectPreparedStatus = (status) => {
    if (delay) {
      clearTimeout(delay);
      setDelay(null);
    }
    setDelay(
      setTimeout(() => {
        sendPutStatus(
          {
            endpoint: `/machine/${status}/${VIN}`,
            headers: { id_token: idToken, access_token: accessToken },
            method: "PUT",
          },
          (response) => {
            console.log("put response: ", response);
            const actions = {
              enable: "พร้อมให้เช่า (Active)",
              disable: "ไม่พร้อมให้เช่า (Non-Active)",
            };
            setStatusSuccess(() => ({
              message: `เปลี่ยน สถานะเครื่องจักรเป็น ${actions[status]}`,
              isShow: true,
              hideDuration: 2000,
            }));
            setMachineStatus(response.status);
          }
        );
      }, 1000)
    );
  };

  const verifyMachine = (verifyStatus) => async () => {
    if (!verifyStatus) return;
    const swalObjs = {
      approved: {
        titleText: "ยืนยันให้ปล่อยเช่าเครื่องจักรหรือไม่",
        icon: "question",
        cancelButtonText: "กลับหน้าเดิม",
        confirmButtonText: "ยืนยัน",
        width: "50%",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        reverseButtons: true,
        customClass: { container: classes.swal2 },
      },
      rejected: {
        titleText: "ปฎิเสธการปล่อยเช่าเครื่องจักร",
        cancelButtonText: "กลับหน้าเดิม",
        confirmButtonText: "ยืนยัน",
        width: "50%",
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: true,
        reverseButtons: true,
        input: "textarea",
        inputLabel: "ระบุเหตุผลเพิ่มเติม",
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value) {
              resolve();
            } else {
              resolve("กรุณากรอกเหตุผลเพิ่มเติม)");
            }
          });
        },
        customClass: { container: classes.swal2__delete },
      },
    };
    const response = await new Swal(swalObjs[verifyStatus]);
    if (response.isConfirmed) {
      sendUpdateRequest(
        {
          endpoint: `/skc/verify/${VIN}`,
          headers: { id_token: idToken, access_token: accessToken },
          method: "POST",
          body: {
            result: verifyStatus,
            ...(verifyStatus === STATUS.REJECTED.nameEN && {
              reason: response.value,
            }),
          },
        },
        (verifyResponse) => {
          console.log("verified machine: ", verifyResponse);
          setStatusSuccess({
            message: "ประเมินเครื่องจักรเรียบร้อยแล้ว",
            isShow: true,
            hideDuration: 2000,
          });
          setTimeout(() => {
            navigate("/machines");
          }, 2000);
        }
      );
    }
  };

  const onSelectMachineTypeHandler = (typeName) => {
    setMachineTypes((prevTypes) => {
      const machineTypesWithSelectedItem = prevTypes.map((type) => ({
        ...type,
        select: type.nameTH === typeName,
        active: type.nameTH === typeName,
      }));
      return machineTypesWithSelectedItem;
    });
    setValue("model", "");
    dispatchMachineOptionsAction({ type: "SELECT_TYPE", name: typeName });
  };

  const onSelectQualityDoc = (file) => {
    if (!file) return;
    const newDoc = {
      ...file,
      flag_delete: false,
    };
    setValue("qualityDoc", file);
    clearErrors("qualityDoc");
    setQualityDoc((prevQualityDoc) => {
      if (prevQualityDoc.length === 1) return [...prevQualityDoc, newDoc];
      return [newDoc];
    });
  };

  const removeQualityDocFile = () => {
    fileInputRef.current.value = "";
    setValue("qualityDoc", null);
    setQualityDoc((prevDoc) => {
      const existedDoc = {
        ...prevDoc[0],
        flag_delete: true,
      };
      if (prevDoc.length === 1)
        return isUrl(prevDoc[0].data) ? [existedDoc] : [];
      if (prevDoc.length === 2) {
        const onlyExistedContract = prevDoc.slice(0, 1);
        return onlyExistedContract;
      }
      return prevDoc;
    });
  };

  const openModalPreviewFile = () => {
    const lastDoc = qualityDoc.at(-1);
    if (isUrl(lastDoc?.data)) window.open(lastDoc.data);
    else setModalPreviewFile(true);
  };

  const closeModalPreviewFile = () => {
    setModalPreviewFile(false);
  };

  const showExampleImage = (imgSrc, imgPosition) => (e) => {
    e.stopPropagation();
    setExampleImage({
      isShow: true,
      imgSrc,
      imgPosition,
    });
  };

  const onRemoveImage = (fieldname, { filename, data }) => {
    if (!fieldname) return;
    if (fieldname === "imageOther") {
      const remainedImages = imageOtherValue.filter(
        (image) => image.filename !== filename
      );
      if (isUrl(data))
        setDeletedImages((prevDeletedImages) => [...prevDeletedImages, data]);
      if (remainedImages.length <= 5) clearErrors("imageOther");
      setValue(fieldname, remainedImages.length > 0 ? remainedImages : "");
    } else {
      if (isUrl(data))
        setDeletedImages((prevDeletedImages) => [...prevDeletedImages, data]);
      setValue(fieldname, "");
    }
  };

  const submitMachineData =
    (status = null) =>
    (formData) => {
      const {
        imageLeft: left,
        imageRight: right,
        imageFront: front,
        imageBack: back,
        imageDial: dial,
        imageOther: other,
      } = formData;

      const imagesFilename = [left, right, front, back, dial, ...other].map(
        (image) => image.filename
      );
      const duplicatedImages = imagesFilename.filter(
        (name, idx, arr) => arr.indexOf(name) !== idx
      );
      if (duplicatedImages.length > 0) {
        setStatusError((prevStat) => ({
          ...prevStat,
          message: (
            <Fragment>
              <Typography variant="subtitle2">
                เพิ่มรูปชื่อซ้ำกัน กรุณาเปลี่ยนชื่อรูปหรือเลือกรูปใหมม่อีกครั้ง
              </Typography>
              <Typography variant="subtitle2">
                ชื่อรูปที่ซ้ำ {duplicatedImages.join(", ")}
              </Typography>
            </Fragment>
          ),
          isShow: true,
        }));
        return;
      }

      /* body data */
      const qualityDocument = VIN
        ? qualityDoc
        : qualityDoc.find((doc) => !doc.flag_delete);

      const body = {
        nameTH: machineOptions.typeSelected,
        model: formData.model,
        hour: formData.hour,
        year: formData.year,
        act: formData.act,
        gps: { having: formData.gps },
        engineNo: formData.engineNo,
        licenseNo: formData.licenseNo,
        VIN: formData.VIN,
        qualityDoc: qualityDocument,
        imageLeft: left,
        imageRight: right,
        imageFront: front,
        imageBack: back,
        imageDial: dial,
        imageOther: other,
        ...(VIN && { deletedImages }),
        ...(status === STATUS.WAITING.nameEN && { status }),
      };

      sendUpdateRequest(
        {
          endpoint: VIN ? `/machine/update/${VIN}` : "/machine/add",
          headers: { id_token: idToken, access_token: accessToken },
          method: VIN ? "PUT" : "POST",
          body,
        },
        (postResponse) => {
          console.log("create || update RES: ", postResponse);
          let statusMessage;
          if (!VIN && !status)
            statusMessage = "เพิ่มเครื่องจักรใหม่เรียบร้อยแล้ว";
          if (VIN && !status)
            statusMessage = "อัพเดตข้อมูลเครื่องจักรเรียบร้อยแล้ว";
          if (VIN && status === STATUS.WAITING.nameEN)
            statusMessage = "ส่งข้อมูลเครื่องจักรเพื่อรอการตรวจสอบแล้ว";
          setStatusSuccess((prevStat) => ({
            message: statusMessage,
            isShow: true,
            hideDuration:
              postResponse.status === STATUS.WAITING.nameEN
                ? 2000
                : prevStat.hideDuration,
          }));
          // if (postResponse.status === STATUS.WAITING.nameEN)
          //   setMachineStatus(postResponse.status);
          // else
          setTimeout(() => {
            navigate("/machines");
          }, 2000);
        }
      );
    };

  const disabledUpdatedForm = VIN && !editMode;
  const enabledUpdatedForm = VIN && editMode;

  const machinePreparedStatus = [
    {
      id: 1,
      value: "enable",
      label: "พร้อมเช่า (Active)",
    },
    {
      id: 2,
      value: "disable",
      label: "ไม่พร้อมเช่า (Non-Active)",
    },
  ];

  const formDataProperties = [
    {
      id: 1,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "model",
      label: "รุ่นสินค้า",
      defaultValue: "",
      menuItems: machineOptions.modelOptions,
      subheaderName: machineOptions.typeSelected
        ? "เลือก รุ่นสินค้า"
        : "เลือก เครื่องจักรก่อน",
      disabled: disabledUpdatedForm,
    },
    {
      id: 2,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "year",
      label: "ปีสินค้า",
      defaultValue: "",
      menuItems: yearOptions(COUNTDOWN_YEAR),
      subheaderName: "เลือก ปีสินค้า",
      disabled: disabledUpdatedForm,
    },
    {
      id: 3,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "hour",
      label: "ชั่วโมงรถปัจจุบัน",
      defaultValue: 0,
      type: "number",
      disabled: disabledUpdatedForm && machineStatus !== STATUS.DISABLE.nameEN,
    },
    {
      id: 4,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "VIN",
      label: "หมายเลขรถ",
      defaultValue: "",
      type: "text",
      helper: (
        <Typography color="error" variant="subtitle2">
          <small>หมายเลขรถ ไม่สามารถแก้ไขได้ภายหลัง</small>
        </Typography>
      ),
      disabled: VIN,
    },
    {
      id: 5,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "engineNo",
      label: "หมายเลขเครื่องยนต์",
      defaultValue: "",
      type: "text",
      disabled: disabledUpdatedForm,
    },
    {
      id: 6,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "licenseNo",
      label: "หมายเลขทะเบียน",
      defaultValue: "",
      type: "text",
      disabled: disabledUpdatedForm,
    },
    {
      id: 7,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "act",
      label: "พ.ร.บ.",
      defaultValue: "",
      menuItems: [
        { label: "มี", value: true },
        { label: "ไม่มี", value: false },
      ],
      valueProp: "value",
      menuProp: "label",
      disabled: disabledUpdatedForm,
    },
    {
      id: 8,
      rowWidth: { xs: 12, md: 6 },
      control: control,
      name: "gps",
      label: "GPS",
      defaultValue: "",
      menuItems: [
        { label: "มี", value: true },
        { label: "ไม่มี", value: false },
      ],
      valueProp: "value",
      menuProp: "label",
      disabled: disabledUpdatedForm,
    },
  ];

  const formImages = [
    {
      id: 1,
      control: control,
      name: "imageLeft",
      nameTH: "ด้านข้างซ้าย",
      defaultValue: "",
      value: imageLeftValue,
      onRemoveImage: onRemoveImage,
      onClickSecondaryAction: showExampleImage,
      disabled: disabledUpdatedForm,
      exampleImage: exImgLeft,
      main: true,
      header: (
        <Typography textAlign="center" variant="subtitle1">
          <strong>รูปหลัก</strong>
        </Typography>
      ),
      childrenText: "ตำแหน่ง: ด้านข้างซ้าย",
    },
    {
      id: 2,
      control: control,
      name: "imageRight",
      nameTH: "ด้านข้างขวา",
      defaultValue: "",
      value: imageRightValue,
      onRemoveImage: onRemoveImage,
      onClickSecondaryAction: showExampleImage,
      disabled: disabledUpdatedForm,
      exampleImage: exImgRight,
      childrenText: "ตำแหน่ง: ด้านข้างขวา",
    },
    {
      id: 3,
      control: control,
      name: "imageFront",
      nameTH: "ด้านหน้า",
      defaultValue: "",
      value: imageFrontValue,
      onRemoveImage: onRemoveImage,
      onClickSecondaryAction: showExampleImage,
      disabled: disabledUpdatedForm,
      exampleImage: exImgFront,
      childrenText: "ตำแหน่ง: ด้านหน้า",
    },
    {
      id: 4,
      control: control,
      name: "imageBack",
      nameTH: "ด้านหลัง",
      defaultValue: "",
      value: imageBackValue,
      onRemoveImage: onRemoveImage,
      onClickSecondaryAction: showExampleImage,
      disabled: disabledUpdatedForm,
      exampleImage: exImgBack,
      childrenText: "ตำแหน่ง: ด้านหลัง",
    },
    {
      id: 5,
      control: control,
      name: "imageDial",
      nameTH: "หน้าปัดชั่วโมงการทำงาน",
      defaultValue: "",
      value: imageDialValue,
      onRemoveImage: onRemoveImage,
      onClickSecondaryAction: showExampleImage,
      disabled: disabledUpdatedForm,
      exampleImage: exImgDial,
      childrenText: "ตำแหน่ง: หน้าปัดชั่วโมงการทำงาน",
    },
    {
      id: 6,
      control: control,
      name: "imageOther",
      nameTH: "อื่นๆ",
      defaultValue: [],
      value: imageOtherValue,
      onRemoveImage: onRemoveImage,
      onClickSecondaryAction: showExampleImage,
      disabled: disabledUpdatedForm,
      multiple: true,
      childrenText: (
        <Fragment>
          อื่นๆ ถ้ามี{" "}
          <Typography
            component="span"
            variant="subtitle2"
            sx={{ color: (theme) => theme.palette.error.light }}
          >
            <strong>*เลือกได้หลายรูป*</strong>
          </Typography>
        </Fragment>
      ),
    },
  ];

  const editAndConfirmedApproveButtons = (machineStatus ===
    STATUS.DRAFT.nameEN ||
    machineStatus === STATUS.REJECTED.nameEN ||
    machineStatus === STATUS.RETURN.nameEN) &&
    disabledUpdatedForm && (
      <Fragment>
        <Fab
          size="small"
          color="secondary"
          onClick={onOpenEditMode}
          variant="extended"
          sx={{ ml: "auto", mr: 2 }}
        >
          <EditIcon sx={{ mr: 1 }} />
          แก้ไขข้อมูล
        </Fab>
        <Fab
          size="small"
          color="primary"
          variant="extended"
          onClick={confirmedSendingApprove}
          sx={{ ml: enabledUpdatedForm && "auto" }}
        >
          <FactCheckIcon sx={{ mr: 1 }} />
          ยืนยันส่งตรวจสอบ
        </Fab>
      </Fragment>
    );

  const activatedMachineInput = (machineStatus === STATUS.DRAFT.nameEN ||
    machineStatus === STATUS.REJECTED.nameEN ||
    machineStatus === STATUS.AVAILABLE.nameEN ||
    machineStatus === STATUS.RETURN.nameEN ||
    machineStatus === STATUS.DISABLE.nameEN) && (
    <Grid container item xs={12} sx={{ flexGrow: 1 }}>
      <InlineRadioInput
        control={control}
        defaultValue=""
        name="preparedStatus"
        label="สถานะบนเว็บไซต์"
        valueProp="value"
        menuProp="label"
        onSelectRadio={onSelectPreparedStatus}
        radioItems={machinePreparedStatus}
      />
    </Grid>
  );

  const deleteButton = machineStatus === STATUS.DISABLE.nameEN && (
    <Button
      size="small"
      color="error"
      onClick={confirmedDeleteMachine}
      variant="contained"
      sx={{ ml: "auto" }}
    >
      <DeleteForeverIcon sx={{ mr: 1 }} />
      ลบเครื่องจักร
    </Button>
  );

  const statusMachine = VIN && (
    <Grid container item justifyContent="flex-end">
      <Typography variant="body2" sx={{ mr: 1, alignSelf: "center" }}>
        สถานะเครื่องจักร
      </Typography>
      <Chip
        label={STATUS[machineStatus.toUpperCase()].nameTH}
        sx={{
          px: 1,
          mt: 0.5,
          borderRadius: 0,
          ...STATUS[machineStatus.toUpperCase()].styles,
        }}
      />
    </Grid>
  );

  const rejectedAlert = rejectedMessage && (
    <Grid item mt={1} mb={2}>
      <MuiAlert severity="error">เหตุผล: {rejectedMessage}</MuiAlert>
    </Grid>
  );

  const imageFormElements = (forms = []) => {
    const formElements = forms.map((form) => {
      if (!form.value || form.value.length === 0)
        return (
          <Fragment key={form.id}>
            <CardFileInput {...form}>
              <Grid container item xs={2} md={2}>
                <AddCircleOutlineIcon
                  sx={{
                    m: "auto",
                    fontSize: (theme) => theme.spacing(4),
                  }}
                />
              </Grid>
              <Grid
                container
                item
                xs
                md
                direction="column"
                sx={{
                  paddingLeft: (theme) => theme.spacing(1),
                  flexGrow: 1,
                }}
              >
                <Grid item>
                  <Typography variant="subtitle2">เลือก ไฟล์</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle2">
                    {form.childrenText}
                  </Typography>
                </Grid>
              </Grid>
              {!form.multiple ? (
                <Grid container item xs={3} mt={{ xs: 1, md: 0 }}>
                  <Button
                    size="small"
                    color="secondary"
                    variant="outlined"
                    onClick={form.onClickSecondaryAction(
                      form.exampleImage,
                      form.nameTH
                    )}
                    sx={{
                      mr: { xs: 0, md: "auto" },
                      mb: "auto",
                      ml: "auto",
                      cursor: "help",
                    }}
                  >
                    ตัวอย่าง
                  </Button>
                </Grid>
              ) : null}
            </CardFileInput>
          </Fragment>
        );
      if (!Array.isArray(form.value) && !form.value.length)
        return (
          <Fragment key={form.id}>
            <PreviewImage
              isMain={form.main}
              imgSrc={form.value.data}
              imgName={form.value.filename}
              imgPosition={form.nameTH}
              field={form.name}
              onRemoveHandler={form.onRemoveImage}
              disabled={form.disabled}
            />
          </Fragment>
        );
      if (Array.isArray(form.value) && form.value.length > 0)
        return form.value.map((formValue, idx) => (
          <Fragment key={idx}>
            <PreviewImage
              imgSrc={formValue.data}
              imgName={formValue.filename}
              imgPosition={form.nameTH}
              field={form.name}
              onRemoveHandler={form.onRemoveImage}
              error={imageOtherError?.message}
              disabled={form.disabled}
            />
          </Fragment>
        ));
      return null;
    });
    return formElements;
  };

  const actionButtons = (!disabledUpdatedForm ||
    machineStatus === STATUS.DISABLE.nameEN) && (
    <Fragment>
      <Button
        color="secondary"
        onClick={() => {
          window.location.reload();
        }}
        sx={{ mt: 1 }}
      >
        ยกเลิก
      </Button>
      <Button
        type="submit"
        variant="contained"
        color="secondary"
        sx={{ ml: 1, mt: 1 }}
      >
        {VIN ? "บันทึกแบบร่าง" : "เพิ่ม"}
      </Button>
    </Fragment>
  );

  const confirmedSendingApproveButton = enabledUpdatedForm &&
    machineStatus !== STATUS.DISABLE.nameEN && (
      <Button
        color="primary"
        variant="contained"
        onClick={confirmedSendingApprove}
        sx={{ ml: 1, mt: 1 }}
      >
        <FactCheckIcon sx={{ mr: 1 }} />
        ยืนยันส่งตรวจสอบ
      </Button>
    );

  const verifyButtons = machineStatus === STATUS.WAITING.nameEN &&
    (checkRole({ role: "skc", upn }) || checkRole({ role: "kin", upn })) &&
    disabledUpdatedForm && (
      <Fragment>
        <Button
          color="error"
          variant="contained"
          onClick={verifyMachine("rejected")}
        >
          <CloseIcon sx={{ mr: 1 }} />
          ปฏิเสธ
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={verifyMachine("approved")}
          sx={{ ml: 2 }}
        >
          <CheckIcon sx={{ mr: 1 }} />
          อนุมัติ
        </Button>
      </Fragment>
    );

  return (
    <Fragment>
      <Grid container mt={1}>
        <Grid container item sx={{ mb: 1, flexGrow: 1 }}>
          <Typography variant="h6" sx={{ alignSelf: "center" }}>
            <strong>ข้อมูลเครื่องจักรที่ลงทะเบียน</strong>
          </Typography>
          {editAndConfirmedApproveButtons}
          {deleteButton}
        </Grid>

        <Form onSubmit={handleSubmit(submitMachineData())}>
          {activatedMachineInput}
          <Grid container item xs={12} mt={1}>
            <Grid
              container
              item
              alignItems="flex-end"
              xs={6}
              sm={7}
              lg={9}
              sx={{ flexGrow: 1 }}
            >
              <Typography variant="subtitle1" sx={{ pt: 0.5 }}>
                (1) รายละเอียดสินค้า
              </Typography>
            </Grid>
            {(statusMachine || rejectedAlert) && (
              <Grid item xs={6} sm={5} lg={3} ml="auto">
                {statusMachine}
                {rejectedAlert}
              </Grid>
            )}
          </Grid>

          <Grid
            container
            item
            justifyContent="center"
            xs={12}
            lg={8}
            mb={2}
            sx={{
              mt: { xs: 2, md: 1 },
            }}
          >
            {machineTypes.map((type, idx) => (
              <MachineTypeList
                key={type.id || idx}
                imgUrl={type.imageUrl}
                name={type.nameTH}
                disabled={disabledUpdatedForm}
                active={type.active}
                select={type.select}
                onClickSelectMachine={onSelectMachineTypeHandler}
              />
            ))}
          </Grid>
          <Grid container item xs={12}>
            {formDataProperties.map(({ rowWidth, ...formProps }) => (
              <Grid key={formProps.id} item mb={2} {...rowWidth}>
                {formProps.menuItems ? (
                  <InlineSelectInput {...formProps} />
                ) : (
                  <InlineInput {...formProps} />
                )}
              </Grid>
            ))}
            <Grid item xs={12} mb={2}>
              <Typography variant="subtitle1">
                (2) การประเมินคุณภาพเครื่องจักร
              </Typography>
            </Grid>
            <Grid item xs={12} mb={2}>
              <FileInput
                control={control}
                name="qualityDoc"
                label="เอกสารการประเมินคุณภาพ"
                type="file"
                accept="application/pdf, image/png, image/jpeg"
                ref={fileInputRef}
                size="medium"
                disabled={disabledUpdatedForm}
                value={!qualityDoc?.at(-1)?.flag_delete && qualityDoc.at(-1)}
                onSelectHandler={onSelectQualityDoc}
                onRemoveHandler={removeQualityDocFile}
                onShowHandler={openModalPreviewFile}
                inputLength={{
                  label: { xs: "40%", md: "30%", lg: "25%" },
                  input: { xs: "60%", md: "70%", lg: "75%" },
                }}
              />
            </Grid>
            <Grid
              container
              item
              justifyContent="flex-start"
              alignItems="center"
              xs={12}
              mb={2}
            >
              <Typography variant="subtitle1" mr={2}>
                (3) รูปภาพ
              </Typography>
            </Grid>
            <Grid container item xs={12} mb={2}>
              {imageFormElements(formImages)}
              {imageOtherError?.message ? (
                <Grid item xs={12}>
                  <Typography
                    textAlign="center"
                    variant="h5"
                    sx={{
                      color: (theme) => theme.palette.error.main,
                    }}
                  >
                    {imageOtherError.message}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
            <Grid container item justifyContent="flex-end" xs={12} mb={4}>
              {actionButtons}
              {confirmedSendingApproveButton}
              {verifyButtons}
            </Grid>
          </Grid>
        </Form>
      </Grid>

      <Modal
        open={modalPreviewFile}
        onClose={closeModalPreviewFile}
        titleWithClose
        dialogTitle={qualityDoc?.at(-1)?.filename}
        fullScreen
        sx={
          qualityDoc?.at(-1)?.filename.endsWith(".pdf") && {
            overflow: "hidden",
          }
        }
      >
        {qualityDoc?.at(-1)?.filename.endsWith(".pdf") ? (
          <IFrameDoc src={qualityDoc?.at(-1)?.data} title="qualityDoc__pdf" />
        ) : (
          <Grid container justifyContent="center">
            <ImageDoc src={qualityDoc?.at(-1)?.data} alt="qualityDoc__img" />
          </Grid>
        )}
      </Modal>

      <Modal
        open={exampleImage.isShow}
        onClose={() =>
          setExampleImage({
            isShow: false,
            imgSrc: "",
            imgPosition: "",
          })
        }
        titleWithClose
        dialogTitle={`ตัวอย่างรูป: ${exampleImage.imgPosition}`}
      >
        <img
          src={exampleImage.imgSrc}
          alt={`ตัวอย่างรูป-${exampleImage.imgPosition}`}
        />
      </Modal>

      <Backdrop
        open={isSending || statusSuccess.isShow}
        sx={(theme) => ({
          color: theme.palette.primary.main,
          zIndex: theme.zIndex.drawer + 1,
          opacity: 1,
          transition: "opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        })}
      >
        <CircularProgress color="inherit" size="5rem" />
      </Backdrop>

      <Alert
        position={{ vertical: "top", horizontal: "center" }}
        open={statusSuccess.isShow}
        hideDuration={statusSuccess.hideDuration}
        onClose={() =>
          setStatusSuccess((prevStat) => ({
            ...prevStat,
            message: "",
            isShow: false,
          }))
        }
        status="success"
        message={statusSuccess.message}
      />

      <Alert
        position={{ vertical: "bottom", horizontal: "center" }}
        open={statusError.isShow}
        hideDuration={4000}
        onClose={() =>
          setStatusError((prevStat) => ({
            ...prevStat,
            message: "",
            isShow: false,
          }))
        }
        status="error"
        message={statusError.message}
      />
    </Fragment>
  );
};

export default MachineFormPage;
