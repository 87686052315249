import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.secondary.main,
  padding: theme.spacing(1),
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: theme.shape.borderRadius,
  "&:hover": {
    backgroundColor: theme.palette.secondary.dark,
    color: theme.palette.secondary.contrastText,
  },
}));

const NotFound404Page = () => {
  return (
    <Fragment>
      <Typography variant="h2" sx={{ mb: 5 }}>
        404 PAGE NOT FOUND
      </Typography>
      <Typography variant="h4" sx={{ mb: 5 }}>
        ไม่พบหน้าเว็บที่ท่านเรียกใช้
      </Typography>
      <StyledLink to="/">กลับหน้าหลัก</StyledLink>
    </Fragment>
  );
};

export default NotFound404Page;
