import { forwardRef } from "react";
import PropTypes from "prop-types";

import { Alert as MuiAlert, Snackbar } from "@mui/material";

const AlertBar = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Alert = ({
  position,
  open,
  onClose,
  message,
  hideDuration,
  status,
  ...props
}) => {
  const onClickClose = () => {
    onClose();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: position.vertical,
        horizontal: position.horizontal,
      }}
      open={open}
      autoHideDuration={hideDuration}
      onClose={onClickClose}
    >
      <AlertBar onClose={onClickClose} severity={status} sx={{ width: "100%" }}>
        {message}
      </AlertBar>
    </Snackbar>
  );
};

Alert.propTypes = {
  position: PropTypes.exact({
    vertical: PropTypes.oneOf(["top", "bottom"]),
    horizontal: PropTypes.oneOf(["left", "center", "right"]),
  }),
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  message: PropTypes.node.isRequired,
  hideDuration: PropTypes.number,
  status: PropTypes.string,
};

Alert.defaultProps = {
  open: false,
};

export default Alert;
