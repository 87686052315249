import { forwardRef } from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import {
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {
  Close as CloseIcon,
  FindInPage as DetailDocumentIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";

import { fileToDataUri } from "helpers/Utilies";

const InlineFormControl = styled(FormControl, {
  shouldForwardProp: (prop) => prop !== "size" && prop !== "inputLength",
})(({ theme, size, inputLength }) => {
  return {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiInputLabel-formControl": {
      display: "flex",
      justifyContent: "flex-start",
      alignSelf: "flex-start",
      position: "static",
      transform: "translate(0,0)",
      flex: `1 0 ${inputLength.label.xs}`,
      paddingTop: theme.spacing(size === "medium" ? 2 : 1),
      paddingLeft: 0,
      [theme.breakpoints.up("md")]: {
        flex: `1 0 ${inputLength.label.md}`,
        justifyContent: "center",
        paddingLeft: theme.spacing(2),
      },
      [theme.breakpoints.up("lg")]: {
        flex: `1 0 ${inputLength.label.lg}`,
      },
    },
    "& .MuiInputBase-root": {
      flexBasis: inputLength.input.xs,
      [theme.breakpoints.up("md")]: {
        flexBasis: inputLength.label.md,
      },
      [theme.breakpoints.up("lg")]: {
        flexBasis: inputLength.label.lg,
      },
    },
  };
});

const ColumnInputWithHelperText = styled("div")(
  ({ theme, value, disabled }) => {
    return {
      display: "flex",
      flexDirection: "column",
      // flexBasis: "100%",
      flexBasis: "50%",
      [theme.breakpoints.up("md")]: {
        flexBasis: "75%",
      },
      "& .MuiFormHelperText-contained": {
        margin: theme.spacing(0.5, 0),
      },
      ...(value?.filename && {
        "& ::file-selector-button": { display: "none" },
        "& input[type=file]": {
          color: "transparent",
        },
        "& input[type=file]::before": {
          content: `"${value.filename}"`,
          color: theme.palette.common.black,
          ...(disabled && { paddingRight: "100%" }),
        },
      }),
    };
  }
);

const FileInput = forwardRef(
  (
    {
      control,
      defaultValue,
      helperMessage,
      inputLength,
      onSelectHandler,
      onRemoveHandler,
      onShowHandler,
      ...props
    },
    ref
  ) => {
    const onSelectFile = (onChange) => async (e) => {
      const { accept: acceptedFile } = props;
      const [file] = e.target.files;
      if (
        acceptedFile.includes("pdf") ||
        acceptedFile.includes("png") ||
        acceptedFile.includes("jpeg")
      ) {
        const resFile = await fileToDataUri(file);
        onSelectHandler(resFile, e.target.name);
        onChange(resFile);
      }
      if (acceptedFile.includes("sheet") || acceptedFile.includes("excel")) {
        onSelectHandler(e);
        onChange(e);
      }
    };

    const onRemoveFile = () => {
      onRemoveHandler(props.name);
    };

    const onShowFile = () => {
      if (!onShowHandler) return null;
      onShowHandler(props.name);
    };

    return (
      <Controller
        name={props.name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { name, onChange }, fieldState: { error } }) => {
          const helperText =
            helperMessage && !error ? (
              <FormHelperText>{helperMessage}</FormHelperText>
            ) : null;
          const errorText = error?.message ? (
            <FormHelperText error={!!error}>{error.message}</FormHelperText>
          ) : null;
          return (
            <InlineFormControl
              disabled={props.disabled}
              size={props.size}
              inputLength={inputLength}
            >
              <InputLabel error={!!error} htmlFor={`${name}__file-input`}>
                {props.label}
              </InputLabel>
              <ColumnInputWithHelperText
                value={props.value}
                disabled={props.disabled}
              >
                <OutlinedInput
                  id={`${name}__file-input`}
                  name={name}
                  type={props.type}
                  onChange={onSelectFile(onChange)}
                  error={!!error}
                  size={props.size}
                  inputProps={{ accept: props.accept }}
                  inputRef={ref}
                  endAdornment={
                    props.value?.filename && (
                      <InputAdornment position="end">
                        {props.accept.includes("sheet") ||
                        props.accept.includes("excel") ? null : (
                          <IconButton onClick={onShowFile} variant="contained">
                            <DetailDocumentIcon />
                          </IconButton>
                        )}
                        <IconButton
                          disabled={props.disabled}
                          onClick={onRemoveFile}
                        >
                          <CloseIcon
                            disabled={props.disabled}
                            color={props.disabled ? "disabled" : "error"}
                          />
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                ></OutlinedInput>
                {helperText}
                {errorText}
              </ColumnInputWithHelperText>
            </InlineFormControl>
          );
        }}
      />
    );
  }
);

FileInput.propTypes = {
  control: PropTypes.object.isRequired,
  helperMessage: PropTypes.string,
  label: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  type: PropTypes.string.isRequired,
  name: PropTypes.string,
  size: PropTypes.oneOf(["medium", "small"]),
  accept: PropTypes.string.isRequired,
  onShowHandler: PropTypes.func,
  inputLength: PropTypes.exact({
    label: PropTypes.shape({
      xs: PropTypes.string,
      sm: PropTypes.string,
      md: PropTypes.string,
      lg: PropTypes.string,
      xl: PropTypes.string,
    }),
    input: PropTypes.shape({
      xs: PropTypes.string,
      sm: PropTypes.string,
      md: PropTypes.string,
      lg: PropTypes.string,
      xl: PropTypes.string,
    }),
  }),
};

FileInput.defaultProps = {
  type: "file",
  disabled: false,
  size: "small",
  onShowHandler: null,
  inputLength: {
    label: {
      xs: "50%",
      sm: "50%",
      md: "50%",
      lg: "50%",
      xl: "50%",
    },
    input: {
      xs: "50%",
      sm: "50%",
      md: "50%",
      lg: "50%",
      xl: "50%",
    },
  },
};

export default FileInput;
