import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const InlineFormControl = styled(FormControl)(({ theme }) => {
  return {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    "& .MuiFormLabel-root": {
      display: "flex",
      flexBasis: "30%",
      justifyContent: "flex-start",
      paddingTop: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        justifyContent: "center",
        paddingLeft: theme.spacing(2),
      },
      [theme.breakpoints.up("lg")]: {
        flexBasis: "20%",
      },
    },
    "& .MuiFormGroup-root": {
      flex: "1 0 70%",
      [theme.breakpoints.up("lg")]: {
        flexBasis: "80%",
      },
      "& .MuiFormHelperText-root": {
        marginLeft: 0,
        paddingTop: 0,
        [theme.breakpoints.up("lg")]: {
          paddingTop: theme.spacing(1.35),
        },
      },
    },
  };
});

const InlineRadioInput = ({
  control,
  defaultValue,
  radioItems,
  valueProp,
  menuProp,
  row,
  onSelectRadio,
  ...props
}) => {
  const onChangeRadio = (callback) => (e) => {
    callback(e);
    onSelectRadio(e.target.value);
  };

  return (
    <Controller
      name={props.name}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { name, value, onChange }, fieldState: { error } }) => {
        const radioItemsElements =
          radioItems.length > 0 &&
          radioItems.map((item, idx) => (
            <FormControlLabel
              key={item.id ? item.id : idx}
              control={<Radio name={name} />}
              value={valueProp ? item[valueProp] : item}
              label={menuProp ? item[menuProp] : item}
            />
          ));

        const errorText = error?.message ? (
          <FormHelperText error={!!error}>{error.message}</FormHelperText>
        ) : null;

        return (
          <InlineFormControl disabled={props.disabled} error={!!error}>
            <FormLabel sx={{ color: "common.black" }}>{props.label}</FormLabel>
            <RadioGroup
              row={row}
              aria-label="gender"
              name="controlled-radio-buttons-group"
              value={value}
              onChange={onChangeRadio(onChange)}
            >
              {radioItemsElements}
              {errorText}
            </RadioGroup>
          </InlineFormControl>
        );
      }}
    />
  );
};

InlineRadioInput.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object.isRequired,
  label: PropTypes.node.isRequired,
  row: PropTypes.bool,
  disabled: PropTypes.bool,
  radioItems: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.arrayOf(PropTypes.object),
  ]).isRequired,
};

InlineRadioInput.defaultProps = {
  disabled: false,
  row: true,
  radioItems: [],
};

export default InlineRadioInput;
