import { Typography } from "@mui/material";

const HomePage = (props) => {
  return (
    <Typography variant="h3" sx={{ mt: 1 }}>
      อัพเดตข่าวสาร
    </Typography>
  );
};

export default HomePage;
