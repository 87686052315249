import { Fragment } from "react";
import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";

const ContactUsLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.secondary.main,
  "&:hover": {
    textDecoration: "underline",
  },
}));

const ServerError500Page = (props) => {
  return (
    <Fragment>
      <Typography variant="h2" sx={{ mb: 5 }}>
        500 INTERNAL SERVER ERROR
      </Typography>
      <Typography variant="h4" sx={{ mb: 1 }}>
        ระบบเซิฟเวอร์เกิดข้อผิดพลาด
      </Typography>
      <Typography variant="h4" sx={{ mb: 5 }}>
        กรุณาเข้าใช้อีกครั้งในภายหลัง หรือติดต่อผู้ดูแลระบบ
      </Typography>
      <Grid container mb={3} justifyContent="center">
        <Grid container item xs={4} justifyContent="flex-end" mr={3}>
          <CallIcon sx={{ mr: 1 }} />
          <ContactUsLink href="tel:(+66)81-005-4241">
            081-005-4241
          </ContactUsLink>
        </Grid>
        <Grid container item xs="auto" mr={3}>
          <EmailIcon sx={{ mr: 1 }} />
          <ContactUsLink href="mailto:contact@kasetinno.com">
            contact@kasetinno.com
          </ContactUsLink>
        </Grid>
        <Grid container item xs={4} justifyContent="flex-start">
          <LanguageIcon sx={{ mr: 1 }} />
          <ContactUsLink href="https://www.kasetinno.com" target="_blank">
            https://www.kasetinno.com
          </ContactUsLink>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ServerError500Page;
